<template>
  <v-row
    justify="center"
    align="center"
    style="min-height:90vh"
  >
    <!-- Week Radio Buttons -->
    <v-col
      id="select-week-radio-group"
      cols="12"
    >
      <v-radio-group
        :value="$store.getters.week"
        row
        style="width: 248px; margin: 5px auto; min-width: 248px;"
        @change="handleWeek"
      >
        <v-radio
          id="lastWeek"
          label="Last Week"
          value="last"
        />
        <v-radio
          id="presentWeek"
          label="This Week"
          value="present"
        />
      </v-radio-group>
    </v-col>

    <!-- WEEK TOTALS -->
    <v-col
      id="totalHours"
      dense
      class="align-center d-flex pa-0"
      style="margin-top: calc(100vh * -0.05)"
      xs="12"
      sm="12"
      md="8"
      lg="8"
    >
      <v-col
        cols="6"
        class="d-flex flex-wrap justify-center"
      >
        <span class="pr-1">Week Total:</span><span> {{ totalHoursForWeek }} hrs</span>
      </v-col>
      <v-col
        cols="6"
        class="d-flex justify-start"
      >
        <v-btn
          id="submit-week-button"
          max-width="90%"
          :disabled="noUnSubmittedEntries"
          @click="confirmSubmit"
        >
          <v-icon
            color="primary"
            :size="20"
            left
          >
            mdi-send
          </v-icon>Submit
        </v-btn>
      </v-col>
    </v-col>

    <!-- WEEK ENTRIES -->
    <v-col
      cols="12"
      class="pt-0"
    >
      <v-expansion-panels
        accordion
        class="align-content-center d-flex flex-column"
      >
        <v-expansion-panel
          v-for="(day, index) in $store.getters.dates"
          :key="day.date"
        >
          <v-expansion-panel-header
            :id="`${day.dayName }_dropdown`"
            :class="{noEntries: [1, 2, 3, 4, 5].includes(index) ? noSavedEntries(day.date) : ''}"
          >
            <v-row style="padding:calc(100vh * .01)">
              <v-col
                cols="4"
                class="text-left"
              >
                {{ day.dayName }}
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                {{ day.date.slice(5) }}
              </v-col>
              <v-col
                cols="4"
                class="text-end"
              >
                {{ (($store.getters.entries || {})[day.date] || {}).totalHours }} hrs
              </v-col>
            </v-row>
            <template
              v-if="hasOfflineRecords(day.date)"
              #actions
            >
              <v-icon color="#553882">
                mdi-circle
              </v-icon>
            </template>
          </v-expansion-panel-header>

          <!-- ENTRIES TABLE -->
          <v-expansion-panel-content eager>
            <RecordsTable
              :date="day.date"
              @updateTimeForm="handleTimeForm"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <!-- INJURY AND COMMENT BUTTONS -->
    <v-col
      class="d-flex flex-start mb-5"
      sm="12"
      md="8"
    >
      <v-btn
        id="commentButton"
        small
        class="flex-grow-1"
        @click="commentPopup = true"
      >
        <v-icon
          v-if="$store.getters.comment[$store.getters.week].comment"
          small
          left
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
          v-else
          small
          left
        >
          mdi-checkbox-blank-outline
        </v-icon>
        Comment
      </v-btn>
      <v-btn
        id="injuryButton"
        class="flex-grow-1 ml-5"
        small
        @click="injuryPopup = true"
      >
        <v-icon
          v-if="$store.getters.injury[$store.getters.week].injury_comment"
          small
          left
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
          v-else
          small
          left
        >
          mdi-checkbox-blank-outline
        </v-icon>
        Injury
      </v-btn>
    </v-col>
    <!-- Footer Buttons -->
    <v-footer
      fixed
      class="d-flex justify-end pa-0"
      style="bottom:30px; background: none; pointer-events: none"
      width="100%"
    >
      <v-btn
        v-if="$store.getters.week === 'present'"
        id="open-quick-record-modal-btn"
        class="mb-1 mr-3 py-5 rounded-circle"
        small
        style="pointer-events: auto; min-width: 43px"
        :color="!$store.state.weekly.quickRecord.start ? 'primary' : 'success'"
        @click="checkQuickRecord"
      >
        <v-icon
          dense
          style="position:absolute;"
        >
          mdi-clock-fast
        </v-icon>
      </v-btn>
    </v-footer>

    <!-- POPUP FOR TIMECARD ENTRY -->
    <v-dialog
      v-model="openTimeForm"
      width="500px"
      max-width="90%"
      persistent
    >
      <TimeForm
        v-if="openTimeForm"
        @updateTimeForm="handleTimeForm"
        @updateQuickRecord="handleQuickRecord"
      />
    </v-dialog>

    <!-- Quick Record -->
    <v-dialog
      v-model="openQR"
      content-class="quickRecord"
      width="500px"
      max-width="90%"
      persistent
    >
      <QuickRecord
        v-if="openQR"
        @updateQuickRecord="handleQuickRecord"
        @updateTimeForm="handleTimeForm"
      />
    </v-dialog>

    <!-- COMMENT POPUP -->
    <v-dialog
      v-model="commentPopup"
      persistent
      width="90%"
      max-width="650px"
      class="mx-2"
    >
      <CommentForm
        v-if="commentPopup"
        :comment="$store.getters.comment[$store.getters.week].comment"
        @closeComment="closeComment"
      />
    </v-dialog>

    <!-- INJURY POPUP -->
    <v-dialog
      v-model="injuryPopup"
      persistent
      width="90%"
      max-width="650px"
      class="mx-2"
    >
      <InjuryForm
        v-if="injuryPopup"
        :injury="$store.getters.injury[$store.getters.week]"
        @closeInjury="closeInjury"
      />
    </v-dialog>

    <!-- User Error Alerts -->
    <Alertbox
      v-bind="alert"
      @close="alert.display=$event"
      @save="alert.display = false; submit();"
    />
    <!-- User Error Alerts -->
  </v-row>
</template>
<script>
/* eslint-disable max-len */
import moment from 'moment/src/moment';
import TimeForm from './components/time_form';
import QuickRecord from './components/quick_record';
import RecordsTable from './components/RecordsTable';
import InjuryForm from './components/injury_form';
import CommentForm from './components/comment_form';
import Alertbox from '../alert/alert.vue';

export default {
  name: 'WeeklyTimecard',
  components: {
    TimeForm,
    QuickRecord,
    RecordsTable,
    InjuryForm,
    CommentForm,
    Alertbox,
  },
  data() {
    return {
      panel: [],
      openTimeForm: false,
      openQR: false,
      injuryPopup: false,
      commentPopup: false,
      alert: {
        display: false,
        message: '',
        confirm: false,
      },
    };
  },
  computed: {
    entries() {
      return (this.$store.getters.entries || {});
    },
    noUnSubmittedEntries() {
      const dates = this.$store.getters.dates || [];
      return dates.every((dateObj) => {
        return ((this.entries[dateObj.date] || {}).entries || []).every((entry) => {
          return entry.submitted !== null;
        });
      });
    },
    totalHoursForWeek() {
      let total = 0;
      this.$store.getters.dates.forEach((dateObj) => {
        total += Number((this.entries[dateObj.date] || {}).totalHours);
      });
      return total;
    },
  },
  methods: {
    roundTime() {
      const time = moment();
      const roundInt = 15;
      const remainder = time.minute() % roundInt;
      return time.subtract(remainder, 'minutes').add(remainder > roundInt / 2 ? roundInt : 0, 'minutes').format('HH:mm:00');
    },
    addQuickRecord() {
      const time = this.roundTime();
      const date = moment().format('YYYY-MM-DD');
      const entry = {
        date,
        id: null,
        job_num: null,
        cstcde: null,
        start: time,
        stop: '',
        total: '',
        lunch: '',
        eq_num: null,
        eqphrs: null,
        emp_num: this.$store.getters.user.emp_num,
        submitted: null,
        field: null,
        office: null,
        payroll: null,
        offsite: null,
      };
      this.$store.commit('setQuickRecord', entry);
      this.openQR = true;
    },
    editQuickRecord() {
      const entry = this.$store.state.weekly.quickRecord;
      entry.stop = this.roundTime();
      this.$store.commit('setQuickRecord', entry);
      this.openQR = true;
    },
    //Checks to see if quick record has been opened already
    checkQuickRecord() {
      if (!this.$store.state.weekly.quickRecord || !this.$store.state.weekly.quickRecord.start) {
        this.addQuickRecord();
      } else {
        this.editQuickRecord();
      }
    },
    noSavedEntries(day) {
      return ((this.entries[day] || {}).entries || []).length === 0;
    },
    handleTimeForm(open) {
      this.openTimeForm = open;
    },
    handleQuickRecord(open) {
      this.openQR = open;
    },
    handleWeek(week) {
      this.$store.dispatch('saveWeek', week);
    },
    closeInjury() {
      this.injuryPopup = false;
    },
    closeComment() {
      this.commentPopup = false;
    },
    hasOfflineRecords(date) {
      const entries = this.$store.getters.offlineEntries;
      return !!entries[date];
    },
    confirmSubmit() {
      const online = this.$store.getters.online;
      if (!online) {
        this.userAlert('You are offline, please connect to submit');
      } else if (Object.keys(this.$store.getters.offlineEntries).length > 0) {
        this.userAlert('You have entries saved on your phone that have not been saved to the Centers, continue?', true);
      } else {
        this.userAlert('Are you sure you want to submit all entries for this week? Once submitted you cannot make changes.', true);
      }
    },
    async checkIfSubmitWasLate() {
      // Check if submit date was late.
      const dates = this.$store.getters.dates;
      const d = moment(dates[6].date);
      d.add(3, 'days').add(12, 'hours');
      const bad = d.format('YYYY-MM-DD hh:mm:ss');
      const td = moment().format('YYYY-MM-DD hh:mm:ss');
      if (td > bad) {
        const user = this.$store.getters.user;
        const userData = {
          subject: `Late Timecard Submitted by ${user.emp_num} - ${user.first_name} ${user.last_name}`,
          message: 'This Employee has been notified that their timecard was submitted late.',
          arrayOfEmails: ['payroll@rotschyinc.com'],
          files: [],
        };
        await this.$store.dispatch('sendEmail', userData);
        this.userAlert('You submitted your Timecard after the cutoff for Payroll. You will need to contact Payroll to make sure you get paid this week.');
      }
    },

    async submit() {
      const week = this.$store.getters.week;
      const userData = {
        empnum: this.$store.getters.user.emp_num,
        lastWeekOrPresentWeek: week,
      };
      await this.$store.dispatch('submitTimecard', userData);
      this.checkIfSubmitWasLate();
    },

    userAlert(message, confirm) {
      this.alert = {
        ...this.alert, message, display: true, confirm,
      };
    },
  },
};
</script>
<style>
  /* We need to figure out why this does not work without being scoped */
  .noEntries {
    color: red;
  }
  .v-dialog {
    margin: 0;
  }
  .v-expansion-panel{
    width:100%;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 ;
  }
  #submit-week-button {
    width: 259px;
  }
  #radioGroup {
    width: 300px;
  }
  .v-expansion-panel {
    max-width: 700px;
  }
  .quickRecord {
    overflow:visible;
  }
</style>
