<template>
  <v-btn
    class="appLink d-flex flex-column"
    :color="color"
    :disabled="availableOffline()"
    :width="scale()"
    :min-height="scale()"
    padding="0"
    @click="route()"
    @touchend="!dragging ? route() : 'do nothing'"
  >
    <div class="d-flex flex-column justify-center">
      <v-icon>{{ icon }}</v-icon>
      <div class="subtitle-2 text-capitalize text-center text-wrap">
        {{ name }}
      </div>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: 'HomeAppLink',
  props: {
    name: {
      type: String,
      default: 'You Must Send A name',
      required: true,
    },
    color: {
      type: String,
      default: 'error',
      required: true,
    },
    size: {
      type: Number,
      default: 0,
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-car-lifted-pickup',
      required: true,
    },
    center: {
      type: String,
      default: '#',
      required: true,
    },
    section: {
      type: String,
      default: '#',
      required: true,
    },
    tab: {
      type: String,
      default: '#',
      required: true,
    },
    kind: {
      type: String,
      default: '#',
      required: true,
    },
    dragging: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      factor: 5,
      native: !(this.center !== 'app' && this.section !== 'app' && this.tab !== 'app'),
    };
  },
  methods: {
    availableOffline() {
      return !this.$store.getters.online && !this.native;
    },
    scale() {
      return this.size > 600 ? '200px' : `${this.size / this.factor}px`;
    },
    route() {
      this.$store.dispatch('setClickedImport', [
        this.center, this.section, this.tab, this.kind,
      ]).then(() => {
        let newloc = this.kind;
        if (!this.native) {
          newloc = '/centers';
        }
        this.$router.push(newloc).catch(() => {});
      });
    },
  },
};
</script>

<style scoped>
.appLink{
    /* border-radius: 50%; */
    margin:5px;
    padding:0;
}
</style>
