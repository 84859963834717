<template>
  <v-app>
    <v-app-bar
      id="appBarHeader"
      app
      fixed
      dense
      class="align-center d-flex justify-space-between"
    >
      <!-- APP BAR TITLE  -->
      <v-toolbar-title
        width="100%"
        class="align-center d-flex justify-space-between"
      >
        <v-btn
          id="navButton"
          icon
          @click.stop="$store.dispatch('setNavigation', {display:true})"
        >
          <v-icon>
            mdi-menu
          </v-icon>
        </v-btn>

        <img
          style="height: 35px; margin-right: 4px;"
          src="img/logo-124x80.e3eb63ee.png"
          @click="goHome"
        />
        {{ $route.name }}
      </v-toolbar-title>

      <v-toolbar-items
        width="100%"
        class="align-center d-flex"
      >
        <!-- REFRESH BUTTON -->
        <v-btn
          v-if="(!$store.getters.updateApp.update || $store.getters.updateApp.later) && $store.getters.online"
          id="refresh-btn"
          icon
          :loading="$store.getters.navigation.loading"
          @click="$store.dispatch('refresh')"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          v-else-if="$store.getters.online"
          color="error"
          icon
          :loading="$store.getters.navigation.loading"
          @click="$store.dispatch('setUpdateApp', {display:true, update:true, later:false})"
        >
          <p
            style="font-size:10px;"
            class="pt-4"
          >
            UPDATE
          </p>
          <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
        >
          <v-icon>
            mdi-wifi-off
          </v-icon>
        </v-btn>
      </v-toolbar-items>
      <!-- Navigation Popout -->
    </v-app-bar>
    <NavDrawer />
    <UpdateApp />
  </v-app>
</template>
<script>
import NavDrawer from './components/navDrawer.vue';
import UpdateApp from './components/updateApp.vue';

export default {
  name: 'NavigationPg',
  components: { NavDrawer, UpdateApp },
  data() {
    return {
      refresh: true,
    };
  },
  created() {
    if (this.$store.getters.myApps.length < 1 && this.$route.path !== '/home') {
      this.$router.push('/home').catch(() => {});
    }
  },
  methods: {
    goHome() {
      if (this.$route.path !== '/home') {
        this.$router.push('/home').catch(() => {});
      }
    },
  },
};
</script>
