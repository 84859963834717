<template>
  <!-- DATE SELECT INPUT -->
  <v-row
    id="timeCardEnterForm"
    justify="space-between"
    align="center"
    class="ma-0 mr-0"
    style="background:#fff;border-radius:10px;overflow-x:hidden;"
    width="100%"
  >
    <v-select
      filled
      shaped
      hide-details="auto"
      dense
      :value="$store.state.weekly.selectedDay"
      :items="$store.getters.dates"
      item-value="date"
      @input="handleDate"
    >
      <template
        #item="props"
      >
        {{ `${props.item.dayName } ${ props.item.date}` }}
      </template>
      <template #selection="selectProps">
        {{ `${selectProps.item.dayName } ${ selectProps.item.date}` }}
      </template>
    </v-select>

    <!-- BUTTON GROUP FOR PTO OFF HOL AND VAC -->
    <v-col
      class="mt-2 py-1"
    >
      <v-btn-toggle
        v-model="entry.cstcde"
        dense
        rounded
      >
        <v-btn
          id="off-btn"
          value="0.000"
        >
          Off
        </v-btn>
        <v-btn
          id="holiday-btn"
          value="160.000"
        >
          Holiday
        </v-btn>
        <v-btn
          id="pto-btn"
          value="140.000"
        >
          PTO
        </v-btn>
      </v-btn-toggle>
    </v-col>

    <!-- FORM FOR TIME ENTRY -->
    <v-form v-if="entry">
      <!-- JOB INPUT -->
      <v-col
        cols="12"
        class="py-2"
      >
        <v-autocomplete
          id="job-input"
          v-model="entry.job_num"
          filled
          class="mt-0"
          hide-details="auto"
          prepend-inner-icon="mdi-crane"
          label="Select Job Number"
          menu-props="closeOnContentClick"
          attach
          :items="jobnumArray"
          :disabled="specialCodes.includes(entry.cstcde)"
          @input="handleJobInput"
        />
      </v-col>

      <!-- COSTCODE INPUT -->
      <v-col
        cols="12"
        class="py-2"
      >
        <v-autocomplete
          id="costcode-input"
          v-model="entry.cstcde"
          filled
          label="Select Cost Code"
          menu-props="closeOnContentClick"
          class="mt-0"
          hide-details="auto"
          attach
          prepend-inner-icon="mdi-decimal-increase"
          :items="costCodeArray"
          :messages="mobilizationCostCode"
          :error-messages="costCodeErrors"
          :disabled="specialCodes.includes(entry.cstcde)"
          @change="handleCostCodeInput"
        />
      </v-col>

      <!-- EQUIPMENT INPUT -->
      <v-col
        cols="12"
        class="py-2"
      >
        <v-autocomplete
          id="equipment-input"
          v-model="entry.eq_num"
          filled
          class="mt-0"
          attach
          menu-props="closeOnContentClick"
          hide-details="auto"
          prepend-inner-icon="mdi-dump-truck"
          label="Equipment"
          :items="equipmentArray"
          :error-messages="equipmentErrors"
          :disabled="specialCodes.includes(entry.cstcde) || (entry.cstcde === '1003.000')"
          @input="handleEquipmentInput"
        />
      </v-col>

      <!-- DESCRIPTION INPUT -->
      <v-col
        cols="12"
        class="py-2"
      >
        <v-textarea
          id="description-input"
          v-model="entry.description"
          rows="1"
          auto-grow
          solo
          prepend-inner-icon="mdi-text-subject"
          :counter="160"
          :error-messages="descriptionErrors"
          :disabled="specialCodes.includes(entry.cstcde)"
          label="Description"
        />
      </v-col>

      <!-- Time Inputs -->
      <v-col
        cols="12"
        class="d-flex justify-center"
        style="margin-top:-50px;"
      >
        <v-col cols="6">
          <v-text-field
            :value="to12Hr(entry.start)"
            label="Start"
            readonly
            style="font-size: .9rem;"
            :error-messages="startTimeErrors"
            :disabled="specialCodes.includes(entry.cstcde) || entry.cstcde === '140.000'"
            @click="timing = {dialog: true, value: entry.start, max: '24:00', min: null, editing: 'start'}"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            :value="to12Hr(entry.stop)"
            readonly
            label="Stop"
            style="font-size: .9rem;"
            :error-messages="stopTimeErrors"
            :disabled="!entry.start || specialCodes.includes(entry.cstcde) || entry.cstcde === '140.000'"
            @click="timing = {dialog: true, value: entry.stop, max: null, min: entry.start, editing: 'stop'}"
          >
          </v-text-field>
        </v-col>
      </v-col>
      <v-col
        v-if="entry.cstcde === '140.000'"
        class="mb-1 pl-7"
        style="margin-top:-40px;"
      >
        <p class="text-left text-subtitle-1">
          Available PTO:  {{ $store.getters.user.pto.availablePTO || 0 }}
        </p>
      </v-col>
      <v-col
        class="d-flex"
        style="margin-top:-40px;"
      >
        <!-- LEFT SIDE  -->
        <v-col
          cols="7"
          class="pa-0"
          style="border-right:.5px solid #ddd"
        >
          <v-col class="py-0">
            <!-- HOURS WORKED INPUT -->
            <v-text-field
              id="hoursWorked"
              v-model.number="newHours"
              filled
              class="mt-0"
              hide-spin-buttons
              hide-details="auto"
              type="number"
              inputmode="decimal"
              label="Hours Worked"
              min="0"
              step="0.25"
              :disabled="specialCodes.includes(entry.cstcde) && entry.cstcde !=='140.000' "
              :error-messages="newHoursErrors"
              @input="handleHoursInput"
            />
            <!-- LUNCH INPUT -->
            <v-text-field
              id="lunch-input"
              v-model.number="entry.lunch"
              filled
              class="mt-3"
              hide-details="auto"
              hide-spin-buttons
              type="number"
              inputmode="decimal"
              label="Lunch"
              min="0"
              step="0.25"
              :error-messages="lunchErrors"
              :disabled="specialCodes.includes(entry.cstcde)"
              @input="calculateTotalFromJustHours"
            />
            <!-- EQUIPMENT HOURS -->
            <v-text-field
              id="eqhrs-input"
              v-model.number="entry.eqphrs"
              filled
              hide-spin-buttons
              type="number"
              inputmode="decimal"
              label="Equipment Hours"
              class="mt-3"
              hide-details="auto"
              min="0"
              step="0.25"
              :disabled="!entry.eq_num || repairCodes.includes(entry.cstcde) || specialCodes.includes(entry.cstcde)
                || (entry.cstcde === '1003.000')"
              :error-messages="equipmentHrsErrors"
            />
          </v-col>
        </v-col>
        <!-- RIGHT SIDE  -->
        <v-col
          cols="4"
          class="mx-4 pa-0"
        >
          <v-text-field
            id="total-hrs"
            v-model="entry.total"
            label="Paid"
            :error-messages="totalErrors"
            readonly
          />
          <v-checkbox
            v-if="entry.cstcde !== '140.000'"
            id="offsite-checkbox"
            v-model="entry.offsite"
            true-value="1"
            :false-value="null"
            label="Offsite"
            class="offsite"
            :disabled="specialCodes.includes(entry.cstcde) || disableOffsite()"
          ></v-checkbox>
          <v-btn
            v-if="entry.cstcde === '140.000'"
            color="info"
            @click="openCashPtoModal"
          >
            Cash PTO
          </v-btn>
        </v-col>
      </v-col>
    </v-form>

    <!-- CANCEL AND SAVE BUTTONS -->
    <v-col class="d-flex justify-space-between">
      <v-btn
        id="cancel-btn"
        color="error"
        @click="handleCancelButton"
      >
        <v-icon left>
          mdi-close
        </v-icon>cancel
      </v-btn>
      <v-btn
        id="save-btn"
        color="success"
        :disabled="$v.$invalid"
        :loading="saving"
        @click="sendEntry(entry)"
      >
        <v-icon left>
          mdi-cloud-upload-outline
        </v-icon>
        Save
      </v-btn>
    </v-col>

    <!-- COST CODE 310 POPUP -->
    <v-dialog
      v-model="costcodeAlertDialog"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title>Missing Job #</v-card-title>
        <v-divider />
        <v-card-text>
          Do You want to Continue Anyways?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="success"
            @click="costcodeAlertDialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="error"
            :loading="saving"
            @click="sendToDatabase(entry)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Start Stop time picker dialog -->
    <v-dialog
      v-model="timing.dialog"
      width="auto"
    >
      <v-time-picker
        v-if="timing.dialog"
        v-model="timing.value"
        :max="timing.max"
        :min="timing.min"
        :allowed-minutes="v => v % 15 === 0"
        @click:minute="handleTiming()"
      >
      </v-time-picker>
    </v-dialog>
    <Alertbox
      v-bind="alert"
      @close="alert.display=$event"
    />
    <v-dialog
      v-model="cashPtoModal"
      content-class="quickRecord"
      width="500px"
      max-width="90%"
      persistent
    >
      <CashPto
        v-if="cashPtoModal"
        @close="cashPtoModal = false"
        @save="saveCashedPto"
      />
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable func-names */
/* eslint-disable camelcase */
/* eslint-disable vue/no-undef-properties */
/* eslint-disable no-alert */
import {
  required,
  requiredIf,
  minValue,
  maxLength,
  maxValue,
} from 'vuelidate/lib/validators';
import moment from 'moment/src/moment';
import timeArrays from '../../../mixins/time-values';
import Alertbox from '../../alert/alert.vue';
import CashPto from './cash_pto';

export default {
  name: 'TimeForDay',
  components: { Alertbox, CashPto },
  mixins: [timeArrays],
  data: () => {
    return {
      saving: false,
      selectedType: 'Regular',
      entry: {
        job_num: '',
        eq_num: '',
        cstcde: '',
      },
      timing: {
        editing: null,
        dialog: false,
        max: null,
        min: null,
        value: null,
      },
      cashPtoModal: false,
      newHours: null,
      costcodeAlertDialog: false,
      specialCodes: ['0.000', '140.000', '160.000'],
      alert: {
        display: false,
        message: '',
      },
    };
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity
  validations() {
    const maxAllowed = () => {
      const totalHours = this.$store.getters.usedTimeForDay.totalDayHours;
      return 24 - totalHours;
    };
    const costcode1015 = () => {
      return !(this.entry.total > 0.25 && this.entry.cstcde === '1015.000');
    };
    const startIndexNotUsed = (value) => {
      const badIndexs = this.makeIndexRangeOfUsed();
      const startIndex = this.newTimeArray.findIndex((timeArrayObj) => {
        return timeArrayObj.value === value;
      });
      if (badIndexs.all.includes(startIndex)) {
        return badIndexs.stops.includes(startIndex);
      }
      return true;
    };
    const validIndexRange = (value) => {
      const startIndex = this.newTimeArray.findIndex((timeArrayObj) => {
        return timeArrayObj.value === this.entry.start;
      });
      const stopIndex = this.newTimeArray.findIndex((timeArrayObj) => {
        return timeArrayObj.value === value;
      });

      const range = [];
      for (let i = startIndex + 1; i <= stopIndex; i++) {
        range.push(i);
      }
      const badIndexs = this.makeIndexRangeOfUsed();

      return !range.some((index) => {
        return (
          badIndexs.all.includes(index) && !badIndexs.starts.includes(index)
        );
      });
    };
    const divisibleByQuarter = (value) => {
      return (value % 0.25 === 0);
    };
    return {
      newHours: {
        minValue: (this.crusherJobsArray(this.$store.getters.crusherJobs).includes(this.entry.job_num)) || (this.entry.cstcde === '0.000') ? minValue(0) : minValue(0.25),
        maxValue: maxValue(maxAllowed()),
        pto: (this.entry.cstcde === '140.000') ? maxValue(Number(this.$store.getters.user.pto.availablePTO)) : true,
      },
      entry: {
        cstcde: {
          required,
        },
        description: {
          maxLength: maxLength(160),
          required,
        },
        eq_num: {
          required: requiredIf(() => {
            return this.repairCodes.includes(this.entry.cstcde);
          }),
        },
        eqphrs: {
          minValue: (this.crusherJobsArray(this.$store.getters.crusherJobs).includes(this.entry.job_num) && this.entry.total > 0) ? minValue(0) : minValue(0.25),
          maxValue: (this.crusherJobsArray(this.$store.getters.crusherJobs).includes(this.entry.job_num)) ? maxAllowed() : maxValue(this.entry.total),
          divisibleByQuarter,
          requiredIf: requiredIf(() => {
            return (
              this.entry.eq_num
                  && !this.repairCodes.includes(this.entry.cstcde)
            );
          }),
        },
        start: {
          startIndexNotUsed: (this.crusherJobsArray(this.$store.getters.crusherJobs).includes(this.entry.job_num)) ? false : startIndexNotUsed,
        },
        stop: {
          validIndexRange: (this.crusherJobsArray(this.$store.getters.crusherJobs).includes(this.entry.job_num)) ? false : validIndexRange,
        },
        lunch: {
          minValue: minValue(0),
          divisibleByQuarter,
        },
        total: {
          required,
          minValue: (this.crusherJobsArray(this.$store.getters.crusherJobs).includes(this.entry.job_num)) || (this.entry.cstcde === '0.000') ? minValue(0) : minValue(0.25),
          maxValue: (this.crusherJobsArray(this.$store.getters.crusherJobs).includes(this.entry.job_num)) ? false : maxValue(maxAllowed()),
          divisibleByQuarter,
          costcode1015,
        },
      },
    };
  },
  computed: {
    // Dropdown Data
    jobnumArray() {
      if (this.$store.state.shared.jobs) {
        const jobArray = this.$store.state.shared.jobs.map((job) => {
          return { text: `${job.job_num} - ${job.job_name}`, value: job.job_num };
        });
        const lastJobs = this.lastUsedInfo.jobs
          .map((job) => {
            return { text: job.description, value: job.job_num };
          });
        return [
          { text: 'None', value: null },
          { header: 'Last Used Job #' },
          ...lastJobs,
          { divider: true },
          { header: 'All Job #' },
          ...jobArray,
        ];
      }
      return ['no jobs'];
    },
    equipmentArray() {
      const textArray = this.$store.state.shared.equipment
        .map((eq) => {
          return { text: `${eq.eq_num} ${eq.description}`, value: eq.eq_num };
        });
      const lastEquipment = this.lastUsedInfo.equipment
        .map((eq) => {
          return { text: eq.description, value: eq.eq_num };
        });
      if (this.$store.state.shared.jobEquipment[this.entry.job_num]) {
        const jobEquipment = this.$store.state.shared.jobEquipment[this.entry.job_num]
          .map((eq) => {
            return { text: eq.description, value: eq.eq_num };
          });
        return [
          { text: 'None', value: null },
          { header: 'Job Equipment' },
          ...jobEquipment,
          { divider: true },
          { header: 'Last Used Equipment' },
          ...lastEquipment,
          { divider: true },
          { header: 'All Equipment' },
          ...textArray,
        ];
      }
      return [
        { text: 'None', value: null },
        { header: 'Last Used Equipment' },
        ...lastEquipment,
        { divider: true },
        { header: 'All Equipment' },
        ...textArray,
      ];
    },
    costCodeArray() {
      const jobnum = this.entry.job_num || null;
      if (!this.$store.state.shared.costcodes || !this.$store.state.shared.costcodes[jobnum]) {
        return [{ text: 'None', value: null }];
      }
      const ccItems = this.$store.state.shared.costcodes[jobnum]
        .map((cc) => {
          return { text: `${cc.costcode_num} ${cc.description}`, value: cc.costcode_num };
        });
      const sorted = ccItems.sort((a, b) => {
        return parseFloat(a.value) - parseFloat(b.value);
      });
      return [
        { text: 'None', value: null },
        { divider: true },
        { header: 'Job Cost Codes' },
        ...sorted,
      ];
    },
    repairCodes() {
      return this.$store.state.shared.repairCodes;
    },
    lastUsedInfo() {
      return this.$store.state.weekly.lastUsedInfo;
    },
    mobilizationCostCode() {
      const messages = [];
      if (this.entry.cstcde === '310.000' && !this.entry.job_num) {
        messages.push('No job number has been selected, make sure this is desired.');
      }
      return messages;
    },

    // Validation Errors
    newHoursErrors() {
      const errors = [];
      if (!this.$v.newHours.$dirty) {
        return errors;
      }
      if (!this.$v.newHours.pto) {
        errors.push('Can\'t exceed your available PTO.');
      }
      if (!this.$v.newHours.maxValue) {
        errors.push('Can\'t exceed 24 hrs in a day.');
      }
      return errors;
    },
    totalErrors() {
      const errors = [];
      if (this.entry.cstcde === '160.000' && this.newHours === 0) {
        errors.push('Part time employees do not get paid holidays, use an off record instead');
      }
      if (this.specialCodes.includes(this.entry.cstcde)) {
        return errors;
      }
      if (!this.$v.entry.total.$dirty) {
        return errors;
      }
      if (!this.$v.entry.total.required) {
        errors.push('Required');
      }
      if (!this.$v.entry.total.minValue) {
        errors.push('Can\'t be less than 0');
      }
      if (!this.$v.entry.total.divisibleByQuarter) {
        errors.push('Must be .25 increment');
      }
      if (!this.$v.entry.total.maxValue) {
        errors.push("Can't exceed 24 hours.");
      }
      if (!this.$v.entry.total.costcode1015) {
        errors.push('Can\'t exceed .25 when using cost code 1015.000');
      }
      return errors;
    },
    costCodeErrors() {
      const errors = [];
      if (!this.$v.entry.cstcde.$dirty) {
        return errors;
      }
      if (!this.$v.entry.cstcde.required) {
        errors.push('Required');
      }
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.entry.description.$dirty) {
        return errors;
      }
      if (!this.$v.entry.description.maxLength) {
        errors.push('Too long');
      }
      if (!this.$v.entry.description.required) {
        errors.push('Required');
      }
      return errors;
    },
    equipmentErrors() {
      const errors = [];
      if (!this.$v.entry.eq_num.$dirty) {
        return errors;
      }
      if (!this.$v.entry.eq_num.required) {
        errors.push('Required when using a Repair Cost Code');
      }
      return errors;
    },
    equipmentHrsErrors() {
      const errors = [];
      if (!this.$v.entry.eqphrs.$dirty) {
        return errors;
      }
      if (!this.$v.entry.eqphrs.maxValue) {
        errors.push('Can\'t exceed total hours worked');
      }
      if (!this.$v.entry.eqphrs.divisibleByQuarter) {
        errors.push('Must be .25 increment');
      }
      if (!this.$v.entry.eqphrs.minValue) {
        errors.push('Must be more than 0');
      }
      if (!this.$v.entry.eqphrs.requiredIf) {
        errors.push('Required if equipment is selected');
      }
      return errors;
    },
    stopTimeErrors() {
      const errors = [];
      if (!this.$v.entry.stop.$dirty) {
        return errors;
      }
      if (!this.$v.entry.stop.validIndexRange) {
        errors.push('You have overlapping time.');
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (!this.$v.entry.start.$dirty) {
        return errors;
      }
      if (!this.$v.entry.start.startIndexNotUsed) {
        errors.push(
          'You have overlapping time.',
        );
      }

      return errors;
    },
    lunchErrors() {
      const errors = [];
      if (!this.$v.entry.lunch.$dirty) {
        return errors;
      }
      if (!this.$v.entry.lunch.minValue) {
        errors.push('Must be 0 or more');
      }
      if (!this.$v.entry.lunch.divisibleByQuarter) {
        errors.push('Must be .25 increment');
      }
      return errors;
    },
  },
  watch: {
    'entry.lunch': function () {
      this.entry.lunch = this.entry.lunch || null;
    },
    'entry.start': function () {
      if (this.entry.start) {
        this.$v.entry.start.$touch();
      }
    },
    'entry.stop': function () {
      if (this.entry.stop) {
        this.$v.entry.stop.$touch();
      }
    },
    'entry.total': function () {
      const total = parseFloat(this.entry.total);
      if (total > 0) {
        const fixedDigits = total.toFixed(2);
        this.entry.total = fixedDigits;
      }
      if (this.repairCodes.includes(this.entry.cstcde)) {
        this.entry.eqphrs = null;
      }
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    'entry.cstcde': function () {
      if (this.specialCodes.includes(this.entry.cstcde) || this.entry.cstcde === '140.000' || (this.entry.cstcde === '1003.000')) {
        switch (this.entry.cstcde) {
          case '0.000': {
            this.newHours = 0;
            this.entry.job_num = null;
            this.entry.cstcde = '0.000';
            this.entry.start = null;
            this.entry.stop = null;
            this.entry.total = 0;
            this.entry.lunch = 0;
            this.entry.eq_num = null;
            this.entry.eqphrs = null;
            this.entry.description = 'Off';
            this.entry.offsite = null;
            break;
          }
          case '140.000': {
            this.newHours = Number(this.newHours) || 0;
            this.entry.job_num = null;
            this.entry.cstcde = '140.000';
            this.entry.description = 'PTO';
            this.entry.start = null;
            this.entry.stop = null;
            this.entry.total = Number(this.newHours) || 0;
            this.entry.lunch = 0;
            this.entry.eq_num = null;
            this.entry.eqphrs = null;
            this.entry.offsite = null;
            break;
          }
          case '1003.000': {
            this.entry.eq_num = null;
            this.entry.eqphrs = null;
            break;
          }
          case '160.000': {
            const theHoliday = this.$store.getters.holidays.find((e) => {
              return e.holiday_date === this.$store.state.weekly.selectedDay;
            });
            if (theHoliday && !Number(theHoliday.unpaid)) {
              const hours = this.$store.getters.user.status === 'fullTime' ? 8 : 0;
              this.entry.job_num = null;
              this.entry.cstcde = '160.000';
              this.entry.description = 'HOLIDAY';
              this.entry.start = null;
              this.entry.stop = null;
              this.entry.total = hours;
              this.newHours = hours;
              this.entry.lunch = 0;
              this.entry.eq_num = null;
              this.entry.eqphrs = null;
              this.entry.offsite = null;
            } else {
              this.entry.cstcde = null;
              this.userAlert('The selected day is not a paid holiday. However, if the office is closed for this day, choose another option such as "Off" or "PTO"');
            }
            break;
          }
          default: break;
        }
      }
      if (this.repairCodes.includes(this.entry.cstcde)) {
        this.entry.eqphrs = null;
      }
    },
  },
  async created() {
    this.$nextTick(() => {
      this.entry = { ...this.$store.state.weekly.timecardEntry };
      if (!this.$store.state.weekly.timecardEntry.id) {
        this.checkForDefaults();
      }
      if (this.entry.stop) {
        this.handleStartStop();
      }
      if (this.entry.start && !this.entry.stop) {
        this.handleStartStop();
      }
      this.setHours();
      this.$v.$touch();
    });
  },
  methods: {
    saveCashedPto(event) {
      const date = this.$store.getters.dates[0].date;
      this.$store.commit('setSelectedDay', date);
      this.entry.description = 'PTO';
      this.newHours = event;
      this.entry.cstcde = '140.000';
      this.entry.total = event;
      this.cashPtoModal = false;
      this.sendEntry(this.entry);
    },
    openCashPtoModal() {
      this.cashPtoModal = true;
    },
    disableOffsite() {
      const pubJobs = this.$store.state.weekly.publicJobs.map((e) => {
        return e.job_num;
      });
      const isNotPublic = !pubJobs.includes(this.entry.job_num);
      if (isNotPublic) {
        this.entry.offsite = null;
      }
      return isNotPublic;
    },
    crusherJobsArray(crusherJobs) {
      const array = [];
      if (crusherJobs) {
        crusherJobs.forEach((job) => {
          array.push(job.job_num);
        });
      }
      return array;
    },
    handleCancelButton() {
      this.$emit('updateTimeForm', false);
    },
    handleDate(date) {
      this.$store.commit('setSelectedDay', date);
    },
    handleHoursInput() {
      this.$v.$touch();
      this.entry.start = null;
      this.entry.stop = null;
      this.calculateTotalFromJustHours();
    },
    handleJobInput(input) {
      this.entry.job_num = input || null;
      const costcodes = this.$store.state.shared.costcodes[this.entry.job_num];
      const cost_code_array = costcodes ? costcodes.map((e) => {
        return e.costcode_num;
      }) : [];
      const defaults = this.$store.state.weekly.defaults;
      //If default is 305 and the costcode is in the array, autopopulate the costcode with 305
      if (defaults.cstcde === '305.000' && cost_code_array.includes('305.000')) {
        this.entry.cstcde = defaults.cstcde;
      //If the current costcode is in the next jobs costcode array as well, leave it be
      } else {
        this.entry.cstcde = this.entry.cstcde === '310.000' ? this.entry.cstcde : null;
      }
      this.entry.eq_num = null;
      this.entry.eqphrs = null;
      this.blurInputs();
    },
    handleCostCodeInput() {
      this.$v.entry.$touch();
      if (this.repairCodes.includes(this.entry.cstcde)) {
        this.entry.eqphrs = null;
      }
      this.blurInputs();
    },
    handleEquipmentInput() {
      const { eq_num } = this.entry;
      if (!eq_num) {
        this.$v.entry.$touch();
        this.entry.eqphrs = null;
      }
      this.blurInputs();
    },
    handleStartStop() {
      this.newHours = 0;
      this.entry.total = 0;
      const lunch = this.entry.lunch || 0;
      const start = this.entry.start;
      const stop = this.entry.stop;
      if (start && stop && start > stop) {
        this.entry.stop = this.incrementTime(start);
      }
      if (start && stop) {
        // Normal time so calculate diff.
        const time = this.startStopDifference(this.entry);
        this.newHours = time;
        this.entry.total = time - lunch;
      }
    },
    incrementTime(time) {
      return moment(time, 'HH:mm:ss').add(15, 'minutes').format('HH:mm:ss');
    },
    startStopDifference(entry) {
      const startTime = moment.duration(entry.start).asHours();
      const stopTime = moment.duration(entry.stop).asHours();
      return stopTime - startTime;
    },

    handleTiming() {
      const obj = this.timing;
      const val = moment(obj.value, 'HH:mm').format('HH:mm:ss');
      const key = obj.editing;
      this.entry[key] = val;
      this.handleStartStop();
      this.timing = {
        dialog: false, value: null, max: null, min: null, editing: null,
      };
    },
    to12Hr(time) {
      if (!time) {
        return null;
      }
      return moment(time, 'HH:mm:ss').format('hh:mm A');
    },

    calculateTotalFromJustHours() {
      const lunch = this.entry.lunch || 0;
      this.entry.total = this.newHours - lunch;
    },
    setHours() {
      const lunch = Number(this.entry.lunch) || 0;
      this.newHours = Number(this.entry.total) + lunch;
    },

    // Sets defaults, does not set start/stop if they were set from a previous record
    checkForDefaults() {
      const storage = this.$store.state.weekly.defaults;
      this.newHours = storage.total ? Number(storage.total) : 0;
      const defaults = {
        cstcde: storage.cstcde || null,
        description: storage.description || null,
        job_num: storage.job_num || null,
        start: this.entry.start || storage.start,
        stop: this.entry.stop || storage.stop,
      };
      if (storage.cstcde === '305.000') {
        defaults.cstcde = null;
      }
      this.entry = { ...this.entry, ...defaults };
      this.calculateTotalFromJustHours();
    },
    blurInputs() {
      document.activeElement.blur();
    },
    makeIndexRangeOfUsed() {
      const allTimeArray = this.newTimeArray;
      const indexObjs = [];
      const stopIndexs = [];
      const startIndexs = [];
      this.$store.getters.usedTimeForDay.startStops.forEach((timeObj) => {
        const startIndex = allTimeArray.findIndex((allTimeObj) => {
          return allTimeObj.value === timeObj.start;
        });
        const stopIndex = allTimeArray.findIndex((allTimeObj) => {
          return allTimeObj.value === timeObj.stop;
        });
        indexObjs.push({ startIndex, stopIndex });
        stopIndexs.push(stopIndex);
        startIndexs.push(startIndex);
      });
      const arrOfIndexs = [];
      indexObjs.forEach((i) => {
        for (let n = i.startIndex; n <= i.stopIndex; n++) {
          arrOfIndexs.push(n);
        }
      });
      return { all: arrOfIndexs, starts: startIndexs, stops: stopIndexs };
    },
    sendEntry(entry) {
      if (this.repairCodes.includes(entry.cstcde)) {
        // eslint-disable-next-line no-param-reassign
        entry.eqphrs = null;
      }
      if (entry.cstcde === '310.000' && !entry.job_num) {
        this.costcodeAlertDialog = true;
      } else {
        this.sendToDatabase(entry);
        this.$emit('updateQuickRecord', false);
      }
    },

    async sendToDatabase(d) {
      const dayEntry = { ...d };
      this.saving = true;
      dayEntry.date = this.$store.state.weekly.selectedDay;
      dayEntry.eq_num = dayEntry.eq_num || null;
      dayEntry.start = dayEntry.start || null;
      dayEntry.stop = dayEntry.stop || null;
      if (this.entry.offline) {
        const userData = {
          timecard_entries: [dayEntry],
        };
        const res = await this.$store.dispatch('saveOfflineRecord', { data: userData, entry: dayEntry });
        this.handleSaveResponse(res);
      } else if (this.entry.id) {
        const userData = {
          timecard_entries: [dayEntry],
        };
        const res = await this.$store.dispatch('saveEditRecord', { data: userData, entry: dayEntry });
        this.handleSaveResponse(res);
      } else {
        const userData = {
          timecard_entries: [dayEntry],
        };
        const res = await this.$store.dispatch('saveNewRecord', { data: userData, entry: dayEntry });
        this.handleSaveResponse(res);
      }
      this.saving = false;
    },
    handleSaveResponse(response) {
      if (response.status === 'error' && response.message === 'Records failed validation') {
        const first = response.data[0].failed_fields;
        let message = Object.values(first)[0][0];
        message = `An error has occurred: ${message}`;
        this.userAlert(message);
      } else if (response.status === 'error' && response.message === 'An error has occurred. Please try again') {
        this.userAlert('An error has occurred. Please refresh and try again.');
      } else {
        const entry = {
          date: null,
          id: null,
          job_num: null,
          cstcde: null,
          start: '',
          stop: '',
          total: '',
          lunch: '',
          eq_num: null,
          eqphrs: null,
          emp_num: this.$store.getters.user.emp_num,
          submitted: null,
          field: null,
          office: null,
          payroll: null,
          offsite: null,
        };
        this.$store.commit('setTimecardEntry', entry);
        this.$store.commit('setSelectedDay', null);
        this.$emit('updateTimeForm', false);
      }
    },
    userAlert(message) {
      this.alert = { ...this.alert, message, display: true };
    },
  },
};
</script>
<style>
  .theme--dark.v-subheader {
    color: #ef9f0ef0;
  }
</style>
