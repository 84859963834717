/* eslint-disable no-param-reassign */
import actions from './actions/index';
import getters from './getters/index';

export default {
  state: {
    clickedImportCstk: [],
  },
  mutations: {
    setClickedImport(state, clickedCstk) {
      state.clickedImportCstk = clickedCstk;
    },
  },
  actions,
  getters,
};
