import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"90%"},model:{value:(_vm.$store.getters.updateApp.display),callback:function ($$v) {_vm.$set(_vm.$store.getters.updateApp, "display", $$v)},expression:"$store.getters.updateApp.display"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"id":"userAlertTitle","outlined":""}},[_c(VCardTitle,{staticClass:"align-items-center d-flex justify-space-between"},[_vm._v(" Update App "),_c('img',{attrs:{"src":require("@/assets/logo-124x80.png"),"alt":"Rotschy Inc","itemprop":"logo","width":"62","height":"40"}})]),_c(VDivider),_c(VCardText,{staticClass:"pa-5",attrs:{"id":"userAlertMessage"}},[_c('object',{staticStyle:{"min-height":"59vh"},attrs:{"type":"text/html","data":`${_vm.$store.getters.server }controller.php?${ _vm.templatename}`,"width":"100%;"}})]),_c(VDivider),_c(VContainer,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"id":"userAlertClose"},on:{"click":function($event){return _vm.updateLater()}}},[_vm._v(" Close ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }