<template>
  <v-container class="pt-0">
    <v-data-table
      id="records-table"
      single-expand
      :items="allEntries"
      :headers="headers"
      :expanded="expanded"
      disable-sort
      disable-pagination
      disable-filtering
      hide-default-footer
      :mobile-breakpoint="0"
      no-data-text="No Time Entries for this Day"
    >
      <template #item="props">
        <tr
          :class="{
            'savedEntry': props.item.id,
            'recordSubmitted': props.item.submitted,
            'offlineEntry': props.item.offline,
          }"
          @click="expand(props)"
        >
          <td>{{ props.item.job_num || ' - - ' }}</td>
          <td>{{ props.item.cstcde }}</td>
          <td>{{ props.item.total }}</td>
          <td>{{ props.item.eq_num || ' - - ' }}</td>
        </tr>
      </template>
      <template
        #expanded-item="props"
      >
        <tr>
          <td
            colspan="4"
            class="pl-0 pr-0"
          >
            <v-container class="d-flex justify-space-between pl-0 pr-0">
              <v-btn
                id="delete-btn"
                color="error"
                outlined
                :loading="deleting"
                :disabled="props.item.submitted=== props.item.emp_num"
                small
                @click="confirmDelete(props.item)"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                Delete
              </v-btn>
              <v-btn
                v-if="props.item.offline"
                id="send-btn"
                small
                outlined
                :disabled="props.item.total > 24"
                class="ml-2"
                @click="editExistingReport(props.item)"
              >
                Send
              </v-btn>
              <v-btn
                v-else
                id="edit-btn"
                :disabled="props.item.submitted === props.item.emp_num || props.item.total > 24"
                outlined
                class="ml-2"
                small
                @click="editExistingReport(props.item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Edit
              </v-btn>
            </v-container>
          </td>
        </tr>
      </template>
      <template #footer>
        <v-container
          fluid
          class="d-flex justify-end pr-0"
        >
          <v-btn
            :id="`add-time-btn-${returnDateName(date)}`"
            light
            small
            @click="addEntryToDay(date)"
          >
            <v-icon
              color="success"
              left
            >
              mdi-plus
            </v-icon>
            add time
          </v-btn>
        </v-container>
      </template>
    </v-data-table>
    <div>
      <Alertbox
        v-bind="alert"
        @close="alert.display=$event"
        @save="deleteEntry($event)"
      />
    </div>
  </v-container>
</template>

<script>
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import moment from 'moment/src/moment';
import Alertbox from '../../alert/alert.vue';

export default {
  name: 'RecordsTable',
  components: {
    Alertbox,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      alert: {
        display: false,
        message: '',
        confirm: false,
      },
      record: {
        offline: false,
      },
      deleting: false,
      headers: [
        {
          text: 'Job',
          value: 'job_num',
        },
        {
          text: 'Cost Code',
          value: 'cstcde',
        },
        {
          text: 'Hours',
          value: 'total',
        },
        {
          text: 'Equip#',
          value: 'eq_num',
        },
      ],
      expanded: [],
    };
  },
  computed: {
    allEntries() {
      // eslint-disable-next-line no-prototype-builtins
      const offline = this.$store.getters.offlineEntries.hasOwnProperty(this.date)
        ? this.$store.getters.offlineEntries[this.date]
        : [];
      const online = ((this.$store.getters.entries[this.date] || {}).entries || []);
      return [...offline, ...online];
    },
  },
  methods: {
    returnDateName(date) {
      return moment(date).format('dddd');
    },
    //Cycles through records for the day and changes the start time to the last stop time
    determineLastUsedStopTime() {
      let startTime = '';
      this.allEntries.filter((entry) => {
        return entry.stop !== '';
      }).forEach((entry) => {
        if (entry.stop > startTime) {
          startTime = entry.stop;
        }
      });
      return startTime;
    },
    expand(props) {
      if (!props.item.submitted) {
        props.expand(!props.isExpanded);
      }
    },
    addEntryToDay(date) {
      const startTime = this.determineLastUsedStopTime();
      const entry = {
        date,
        id: null,
        job_num: null,
        cstcde: null,
        start: startTime,
        stop: '',
        total: '',
        lunch: '',
        eq_num: null,
        eqphrs: null,
        emp_num: this.$store.getters.user.emp_num,
        submitted: null,
        field: null,
        office: null,
        payroll: null,
        offsite: null,
      };
      this.$store.commit('setTimecardEntry', entry);
      this.$store.commit('setSelectedDay', date);
      this.$emit('updateTimeForm', true);
    },
    editExistingReport(record) {
      this.$store.commit('setTimecardEntry', record);
      this.$store.commit('setSelectedDay', record.date);
      this.$emit('updateTimeForm', true);
    },
    async confirmDelete(record) {
      this.record = record;
      this.userAlert('Are you sure you want to delete this record?', true);
    },
    async deleteEntry(confirm) {
      this.alert.display = false;
      const record = this.record;
      if (record.offline) {
        this.$store.dispatch('deleteOfflineEntry', record);
      } else {
        this.deleting = true;
        if (confirm) {
          await this.$store.dispatch('deleteEntry', record);
          this.deleting = false;
        }
      }
    },
    userAlert(message, confirm) {
      this.alert = {
        ...this.alert, message, display: true, confirm,
      };
    },
  },
};
</script>

<style>
  .savedEntry {
    background-color: #f9f938;
  }
  .recordSubmitted {
    background-color:rgb(26, 110, 177);
    color: white;
  }
  .offlineEntry {
    background-color: #553882;
    color: white;
  }
</style>
