import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{staticClass:"align-center d-flex justify-space-between",attrs:{"id":"appBarHeader","app":"","fixed":"","dense":""}},[_c(VToolbarTitle,{staticClass:"align-center d-flex justify-space-between",attrs:{"width":"100%"}},[_c(VBtn,{attrs:{"id":"navButton","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('setNavigation', {display:true})}}},[_c(VIcon,[_vm._v(" mdi-menu ")])],1),_c('img',{staticStyle:{"height":"35px","margin-right":"4px"},attrs:{"src":"img/logo-124x80.e3eb63ee.png"},on:{"click":_vm.goHome}}),_vm._v(" "+_vm._s(_vm.$route.name)+" ")],1),_c(VToolbarItems,{staticClass:"align-center d-flex",attrs:{"width":"100%"}},[((!_vm.$store.getters.updateApp.update || _vm.$store.getters.updateApp.later) && _vm.$store.getters.online)?_c(VBtn,{attrs:{"id":"refresh-btn","icon":"","loading":_vm.$store.getters.navigation.loading},on:{"click":function($event){return _vm.$store.dispatch('refresh')}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1):(_vm.$store.getters.online)?_c(VBtn,{attrs:{"color":"error","icon":"","loading":_vm.$store.getters.navigation.loading},on:{"click":function($event){return _vm.$store.dispatch('setUpdateApp', {display:true, update:true, later:false})}}},[_c('p',{staticClass:"pt-4",staticStyle:{"font-size":"10px"}},[_vm._v(" UPDATE ")]),_c(VIcon,[_vm._v("mdi-update")])],1):_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(" mdi-wifi-off ")])],1)],1)],1),_c('NavDrawer'),_c('UpdateApp')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }