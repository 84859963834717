<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <v-row
        v-if="week === 'present'"
        no-gutters
        justify="center"
        class="pb-0"
      >
        <v-select
          v-model="entry.date"
          :disabled="Number(entry.approved) === 1"
          :error-messages="validateInput('date')"
          prepend-inner-icon="mdi-calendar-month"
          label="Date"
          filled
          :value="$store.state.weekly.selectedDay"
          :items="thisWeekWithoutFutureDates"
          item-value="date"
        >
          <template #item="props">
            {{ `${props.item.dayName } ${ props.item.date}` }}
          </template>
          <template #selection="selectProps">
            {{ `${selectProps.item.dayName } ${ selectProps.item.date}` }}
          </template>
        </v-select>
      </v-row>
      <v-row
        v-if="week === 'last'"
        no-gutters
        justify="center"
      >
        <v-select
          v-model="entry.date"
          :disabled="Number(entry.approved) === 1"
          prepend-inner-icon="mdi-calendar-month"
          :error-messages="validateInput('date')"
          label="Date"
          filled
          :value="$store.state.weekly.selectedDay"
          :items="$store.getters.lastWeekDates"
          item-value="date"
        >
          <template #item="props">
            {{ `${props.item.dayName } ${ props.item.date}` }}
          </template>
          <template #selection="selectProps">
            {{ `${selectProps.item.dayName } ${ selectProps.item.date}` }}
          </template>
        </v-select>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-form
        v-if="entry"
        :disabled="Number(entry.approved) === 1"
      >
        <!-- JOB INPUT -->
        <v-autocomplete
          v-model="entry.job_num"
          filled
          attach
          dense
          :items="jobList"
          :error-messages="validateInput('job_num')"
          prepend-inner-icon="mdi-crane"
          label="Select Job Number"
          @change="blurInputs()"
        />
        <!-- Truck Input -->

        <v-autocomplete
          v-model="entry.truck"
          filled
          attach
          dense
          :items="truckList"
          :error-messages="validateInput('truck')"
          prepend-inner-icon="mdi-crane"
          label="Truck"
          @change="blurInputs()"
        />
        <!-- Employee Input -->
        <v-autocomplete
          v-model="entry.emp_num"
          filled
          attach
          dense
          :items="employeeList"
          :error-messages="validateInput('emp_num')"
          prepend-inner-icon="mdi-crane"
          label="Employee"
        />
        <!-- EQUIPMENT INPUT -->
        <v-autocomplete
          v-model="entry.eq_num"
          filled
          dense
          attach
          :items="equipmentArray"
          :error-messages="validateInput('eq_num')"
          prepend-inner-icon="mdi-dump-truck"
          label="Equipment"
          @change="setPrimaryUnitMeasure(entry.eq_num); blurInputs()"
        />
        <!-- EQUIPMENT UNIT OF MEASURE -->
        <v-select
          v-if="entry.primary_unit_measure"
          :value="entry.primary_unit_measure"
          dense
          disabled
          hide-details
          class="mb-1"
          :items="pum"
          append-icon=""
          label="Primary Unit Measure"
        />
        <v-row>
          <v-col>
            <!-- EQUIPMENT HOURS -->
            <v-text-field
              v-model="entry.current_hours"
              filled
              dense
              :error-messages="(Number(entry.primary_unit_measure) === 0
                ? validateInput('current_hours')
                : []).concat(strictNum('current_hours'))"
              prepend-inner-icon="mdi-clock"
              type="number"
              inputmode="decimal"
              hide-spin-buttons
              label="Hours"
              min="0"
              step="0.25"
              @keypress="onlyNumsAllowed($event)"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <!-- EQUIPMENT Miles -->
            <v-text-field
              v-model="entry.current_miles"
              filled
              dense
              :error-messages="(Number(entry.primary_unit_measure) === 1
                ? validateInput('current_miles')
                : []).concat(strictNum('current_miles'))"
              prepend-inner-icon="mdi-road"
              type="number"
              inputmode="decimal"
              hide-spin-buttons
              label="Miles"
              min="0"
              step="0.25"
              @keypress="onlyNumsAllowed($event)"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- GALLONS -->
        <v-text-field
          v-model="entry.gallons"
          filled
          dense
          :error-messages="validateInput('gallons').concat(strictNum('gallons'))"
          prepend-inner-icon="mdi-gas-station-outline"
          type="number"
          inputmode="decimal"
          hide-spin-buttons
          label="Gallons"
          min="0"
          step="0.25"
          @keypress="onlyNumsAllowed($event)"
        >
        </v-text-field>
        <v-textarea
          v-model="entry.note"
          rows="1"
          label="Notes"
          auto-grow
          filled
        >
        </v-textarea>
      </v-form>
    </v-card-text>

    <!-- CANCEL AND SAVE BUTTONS -->
    <h3
      v-if="Number(entry.approved) === 1"
      style="color: red"
      class="mx-2"
    >
      Entry has been approved and can't be edited
    </h3>
    <v-card-actions class="pt-0">
      <v-flex align-self-end>
        <v-spacer></v-spacer>

        <v-btn
          v-if="existingRecord"
          x-large
          :disabled="Number(entry.approved) === 1"
          color="error"
          class="ma-2"
          @click="removeRecord"
        >
          <v-icon left>
            mdi-close
          </v-icon>delete
        </v-btn>

        <v-btn
          color="orange"
          class="ma-2"
          x-large
          @click="close"
        >
          <v-icon left>
            mdi-close
          </v-icon>cancel
        </v-btn>

        <v-btn
          id="reviewButton"
          color="success"
          x-large
          :loading="saving"
          class="ma-2"
          :disabled="submitDisable || Number(entry.approved) === 1"
          @click="sendEntry(false)"
        >
          <v-icon left>
            mdi-cloud-upload-outline
          </v-icon>
          Save
        </v-btn>
      </v-flex>
    </v-card-actions>
    <Alertbox
      v-bind="alert"
      @close="alert.display=$event; saving = false"
      @save="alert.display=$event; sendEntry(true)"
    />
  </v-card>
</template>
<script>
import moment from 'moment/src/moment';
import fetchDataNewAPI from '../../mixins/fetchDataNewAPI.js';
import Alertbox from '../alert/alert.vue';

export default {
  name: 'SlipTankEntry',
  components: {
    Alertbox,
  },
  mixins: [fetchDataNewAPI],
  props: {
    loggedInUser: {
      type: String,
      required: true,
    },
    truck: {
      type: String,
      required: false,
      default: null,
    },
    week: {
      type: String,
      required: true,
    },
    existingRecord: {
      type: Boolean,
      required: true,
    },
    recordClickedOn: {
      type: Object,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    equipment: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      pum: [
        {
          text: 'Miles',
          value: '1',
        }, {
          text: 'Hours',
          value: '0',
        },
      ],
      entry: {
        id: null,
        emp_num: null,
        date: '',
        truck: null,
        job_num: '',
        location_id: null,
        eq_num: '',
        primary_unit_measure: '',
        gallons: '',
        approved: 0,
        current_hours: null,
        current_miles: null,
        note: null,
      },
      allTrucks: [],
      allEmployees: [],
      alert: {
        display: false,
        message: '',
        confirm: false,
      },
      saving: false,
    };
  },
  computed: {
    thisWeekWithoutFutureDates() {
      const today = moment().format('YYYY-MM-DD');
      return this.$store.getters.dates.filter((day) => {
        return day.date <= today;
      });
    },
    // Disables save, depending on the primary unit of the selected equipment
    submitDisable() {
      const arr = ['primary_unit_measure', 'date', 'gallons', 'job_num', 'emp_num', 'truck'];
      const needsNumCheck = ['gallons', 'current_miles', 'current_hours'];
      arr.push(Number(this.entry.primary_unit_measure) ? 'current_miles' : 'current_hours');
      return arr.some((key) => {
        return (this.validateInput(key)).length > 0;
      }) || needsNumCheck.some((key) => {
        return (this.strictNum(key)).length > 0;
      });
    },
    equipmentArray() {
      const textArray = this.$store.state.shared.equipment
        .map((eq) => {
          return { text: `${eq.eq_num} ${eq.description}`, value: eq.eq_num };
        });
      if (this.$store.state.shared.jobEquipment[this.entry.job_num]) {
        const jobEquipment = this.$store.state.shared.jobEquipment[this.entry.job_num]
          .map((eq) => {
            return { text: eq.description, value: eq.eq_num };
          });
        return [
          { header: 'Job Equipment' },
          ...jobEquipment,
          { divider: true },
          { header: 'All Equipment' },
          ...textArray,
        ];
      }
      return [
        { header: 'All Equipment' },
        ...textArray,
      ];
    },

    jobList() {
      const j = this.locations;
      return j.map((e) => {
        return { value: e.job_num, text: e.description };
      });
    },
    truckList() {
      if (!this.allTrucks) {
        return [];
      }

      const j = this.allTrucks.filter((e) => {
        return [1, 2].includes(Number(e.status));
      });
      return j.map((eq) => {
        return { value: eq.eq_num, text: `${eq.eq_num} - ${eq.description}` };
      });
    },
    employeeList() {
      const j = this.allEmployees;
      return j.map((e) => {
        return { value: e.emp_num, text: `${e.emp_num} - ${e.fstnme} ${e.lstnme}` };
      });
    },
  },
  async created() {
    if (this.existingRecord) {
      this.entry = { ...this.recordClickedOn };
      if (this.recordClickedOn.primary_unit_measure === '1') {
        this.entry.primary_unit_measure = '1';
      } else {
        this.entry.primary_unit_measure = '0';
      }
    }
    this.setupDataAndDropdowns();
  },
  methods: {
    async setupDataAndDropdowns() {
      this.allTrucks = await this.returnTrucks();
      this.allEmployees = this.$store.getters.employees;
      if (!this.entry.emp_num) {
        this.entry.emp_num = this.loggedInUser;
      }
      if (!this.entry.truck) {
        this.entry.truck = this.truck;
      }
      if (!this.entry.date) {
        this.entry.date = moment().format('YYYY-MM-DD');
      }
    },
    setPrimaryUnitMeasure(eqNum) {
      const thisEquip = this.equipment.find((e) => {
        return e.eq_num === eqNum;
      });
      this.entry.primary_unit_measure = Number(thisEquip.primary_unit_measure) ? '1' : '0';
    },
    onlyNumsAllowed(evt) {
      //only works if using @keypress
      if (!parseFloat(evt.key) && evt.key !== '0' && evt.key !== '.') {
        evt.preventDefault();
      }
    },

    // Blurs inputs, closing the keyboard
    blurInputs() {
      document.activeElement.blur();
    },

    close() {
      this.entry = {
        id: null,
        emp_num: null,
        date: '',
        truck: null,
        job_num: '',
        location_id: null,
        eq_num: '',
        primary_unit_measure: null,
        gallons: '',
        approved: 0,
        current_hours: null,
        current_miles: null,
        note: null,
      };
      this.$emit('cancel');
    },
    async removeRecord() {
      await this.deleteData(this.entry.id);
      this.close();
    },
    async sendEntry(skip) {
      this.saving = true;
      let entryToSubmit = {
        id: null,
        emp_num: this.entry.emp_num,
        date: this.entry.date,
        truck: this.entry.truck,
        job_num: this.entry.job_num,
        location_id: this.locations.filter((job) => {
          return job.job_num === this.entry.job_num;
        })[0].location_id,
        eq_num: this.entry.eq_num,
        primary_unit_measure: this.entry.primary_unit_measure,
        current_hours: this.entry.current_hours,
        current_miles: this.entry.current_miles,
        gallons: this.entry.gallons,
        note: this.entry.note || null,
      };
      entryToSubmit = this.convertObjectValuesFromStringToNull(entryToSubmit);
      if (this.existingRecord) {
        entryToSubmit.id = this.recordClickedOn.id;
      }
      const validated = skip ? true : await this.validateHoursMiles(entryToSubmit);
      if (!validated) {
        return;
      }
      const response = await this.saveData(entryToSubmit);
      this.saving = false;
      if (response) {
        this.close();
      }
    },
    // Takes in object, returns same object, but with nulls instead of empty strings
    convertObjectValuesFromStringToNull(obj) {
      const objToReturn = obj;
      Object.keys(obj).forEach((key) => {
        if (objToReturn[key] === '') {
          objToReturn[key] = null;
        }
      });
      return objToReturn;
    },
    async validateHoursMiles(r) {
      const userData = {
        eq_num: r.eq_num,
        unit_of_measure: r.primary_unit_measure,
        current_hour_or_mile_reading: (Number(r.primary_unit_measure)) ? r.current_miles : r.current_hours,
        date_of_reading: r.date,
      };
      const response = await this.checkMeterCall(userData);
      if (response && response.accurate) {
        return true;
      }
      if (!response.accurate) {
        await this.userAlert(`${response.reading_message}, Continue anyways?`, true);
        return false;
      }
      return false;
    },

    async saveData(sentData) {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'set', 'single', 'sliptankRecord'];
      const userData = sentData;
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: server });
      if (!response.error) {
        return true;
      }
      // eslint-disable-next-line no-alert
      alert('There was an error saving, please try again');
      return false;
    },
    async returnTrucks() {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'return', 'all', 'series17Equipment'];
      const response = await this.fetchDataNew(cstk, { alternate_url: server });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      if (!response.error) {
        return [];
      }
      return false;
    },
    async checkMeterCall(userData) {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'action', 'single', 'checkIfMeterReadingIsAccurate'];
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: server });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      return (!response.error);
    },
    async deleteData(sentData) {
      const server = `${this.$store.getters.server}controller.php`;
      const cstk = ['equipment', 'delete', 'single', 'sliptankRecord'];
      const userData = {
        id: sentData,
      };
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: server });
      if (!response.error) {
        return response.response.data;
      }
      return false;
    },
    validateInput(key) {
      const errors = [];
      const val = this.entry[key];
      if (!val) {
        errors.push('Required');
      } else if (key === 'gallons') {
        const numberOfDigits = val.split('.')[0].length;
        if (numberOfDigits > 3) {
          errors.push('Can\'t be more than 3 digits');
        }
      }
      return errors;
    },
    strictNum(key) {
      const val = this.entry[key];
      // eslint-disable-next-line no-restricted-globals
      if (val && isNaN(val)) {
        return ['Is not a number'];
      }
      return [];
    },
    // ALERT MANAGE FUNCTION
    userAlert(message, confirm) {
      this.alert = {
        ...this.alert, message, display: true, confirm,
      };
    },
  },
};
</script>
<style>
  .theme--dark.v-subheader {
    color: #ef9f0ef0;
  }
</style>
