<template>
  <div>
    <v-row
      v-if="!loading"
      id="iftaEntry"
      v-touch="{
        right: () => switchView(),
        left: () => handleEntrySwitch(info.entries.length + 1),
      }"
      class="mt-2 px-1"
      no-gutters
      style="min-height:80vh"
    >
      <!-- Date & Truck Setup Row -->
      <!-- Date Column -->
      <v-col
        cols="3"
        align="center"
      >
        <!-- BACK BUTTON -->
        <v-btn
          id="ifta-back-btn"
          icon
          outlined
          color="success"
          @click="handleBack"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </v-col>

      <v-col
        cols="9"
        class="mb-2"
      >
        <v-select
          v-model="date"
          filled
          dense
          hide-details="auto"
          :disabled="isSubmitted"
          :items="allDates"
          @change="handleDate"
        />
      </v-col>
      <!-- Truck and Trailers Column -->
      <v-col
        v-if="showInitInputs"
        class="align-content-center d-flex justify-center mb-4 mt-1"
        cols="12"
      >
        <v-autocomplete
          id="ifta-enter-truck-input"
          v-model="info.record.eq_num"
          label="Truck"
          class="mr-2"
          attach
          dense
          hide-details="auto"
          :items="iftaEquipmentList"
          :disabled="isSubmitted"
          :error-messages="truckErrors"
          @change="getMiles(); blurInputs()"
        >
          <template #selection="data">
            {{ data.item.value }}
          </template>
        </v-autocomplete>

        <v-autocomplete
          id="ifta-enter-trailer-input"
          v-model="info.record.trailer_1_eq_num"
          class="ml-2"
          dense
          attach
          hide-details="auto"
          :items="trailers"
          :disabled="isSubmitted"
          label="Trailer"
          @change="blurInputs()"
        >
          <template #selection="data">
            {{ data.item.value }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-btn
          v-if="!viewAll && info.entries.length > 0"
          id="view-all-ifta-entries-btn"
          color="teal"
          outlined
          class="mx-2"
          @click="switchView()"
        >
          View All
        </v-btn>
        <v-btn
          v-if="!showInitInputs"
          id="finish-ifta-record-btn"
          class="mx-2"
          outlined
          color="success"
          @click="handleEntrySwitch(info.entries.length + 1)"
        >
          Finish
        </v-btn>
        <v-btn
          v-if="!showInitInputs && !viewAll"
          id="remove-ifta-entry-btn"
          outlined
          color="red"
          class="mx-2"
          :disabled="isSubmitted"
          @click="deleteEntry = true;"
        >
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </v-col>
      <v-row
        v-if="showEntries"
        no-gutters
      >
        <v-col
          :key="renderKey"
          cols="12"
          class="pa-0"
        >
          <v-card
            flat
            outlined
            :class="['mb-1 mt-2',
                     { validationError: (validEntries[entryIndex] || {}).valid === 0,
                       inspection: currentTimecardEntry.cstcde === '1015.000',
                       driveColor: currentTimecardEntry.driving_record === '1',
                       duty: (currentTimecardEntry.driving_record === '0' && currentTimecardEntry.cstcde !== '1015.000'),
                     },
            ]"
          >
            <!-- INSPECTION ENTRIES -->
            <v-col v-if="currentTimecardEntry.cstcde === '1015.000'">
              <Inspection
                :id="`inspection-card-${entryIndex}`"
                :index="entryIndex"
                :main-entry="currentTimecardEntry"
                :ifta-equipment-list="iftaEquipmentList"
                :increment-time="incrementTime"
                :handle-hours="handleHours"
                :to-twelve-hr="toTwelveHr"
                :to-proper-time="toProperTime"
                :other-used-time="otherUsedTime(entryIndex)"
                :overlapping="overLappingRecord"
                :submitted="isSubmitted"
                @entryChanged="handleChangedEntry($event, entryIndex)"
              />
            </v-col>

            <!-- DRIVE ENTRIES -->
            <v-col v-else-if="currentTimecardEntry.driving_record === '1'">
              <DriveEntry
                :id="`drive-card-${entryIndex}`"
                :index="entryIndex"
                :equipment-info="info"
                :increment-time="incrementTime"
                :main-entry="currentTimecardEntry"
                :jobs="jobs"
                :equipment="equipment"
                :handle-hours="handleHours"
                :to-twelve-hr="toTwelveHr"
                :to-proper-time="toProperTime"
                :other-used-time="otherUsedTime(entryIndex)"
                :overlapping="overLappingRecord"
                :submitted="isSubmitted"
                @openInfoDialog="openCostcodeDialog()"
                @entryChanged="handleChangedEntry($event, entryIndex)"
              />
            </v-col>

            <!-- DUTY ENTRIES -->
            <v-col v-else>
              <DutyEntry
                :id="`duty-card-${entryIndex}`"
                :index="entryIndex"
                :main-entry="currentTimecardEntry"
                :increment-time="incrementTime"
                :jobs="jobs"
                :equipment="equipment"
                :handle-hours="handleHours"
                :to-twelve-hr="toTwelveHr"
                :to-proper-time="toProperTime"
                :other-used-time="otherUsedTime(entryIndex)"
                :overlapping="overLappingRecord"
                :submitted="isSubmitted"
                :equipment-info="info"
                @openInfoDialog="openCostcodeDialog()"
                @entryChanged="handleChangedEntry($event, entryIndex)"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <v-col
        v-if="viewAll && !showInitInputs"
        class="ma-0 pa-0"
        cols="12"
      >
        <AllEntryView
          :all-entries="info.entries"
          :other-used-time="otherUsedTime"
          :overlapping="overLappingRecord"
          :valid-entries="validEntries"
          @open="handleEntrySwitch($event); viewAll = false;"
        >
        </AllEntryView>
      </v-col>
      <!-- Total Hours Row    -->
      <v-col
        cols="12"
        class="my-2"
      >
        <v-card
          id="total-and-drive-times-card"
          flat
          outlined
          style="overflow:hidden;"
          class="align-content-center d-flex justify-center"
        >
          <v-col
            align="center"
            class="pa-0"
          >
            <b>
              Drive Time:
              {{ totalDriveTime || 0 }}
            </b>
          </v-col>
          <v-divider vertical />
          <v-col
            align="center"
            class="pa-0"
          >
            <b>
              Total Hours:
              {{ totalTime || 0 }}
            </b>
          </v-col>
        </v-card>
      </v-col>
      <!-- Odo and Miles Row -->
      <v-col
        v-if="showInitInputs"
        class="align-content-center d-flex justify-center"
        cols="12"
      >
        <v-col
          class="pa-0 pr-1"
          style="border-right:1px solid #ddd"
        >
          <p
            style="font-size: 13px"
            class="mb-3 text-center"
          >
            Odometer & Fuel
          </p>
          <v-text-field
            id="ifta-miles-begin-input"
            :value="info.record.odometer_begin"
            label="Miles Begin"
            filled
            pattern="[0-9]*"
            dense
            hide-details="auto"
            type="tel"
            class="mt-1"
            :disabled="isSubmitted"
            :error-messages="odometer_beginErrors"
            @input="handleOdometerBegin"
          >
            <template
              #message="props"
            >
              <div id="miles-begin-errors">
                {{ props.message }}
              </div>
            </template>
          </v-text-field>
          <v-text-field
            id="ifta-miles-end-input"
            :value="info.record.odometer_end"
            label="Miles End"
            filled
            pattern="[0-9]*"
            dense
            hide-details="auto"
            type="tel"
            class="mt-1"
            :disabled="isSubmitted"
            :error-messages="odometer_endErrors"
            @input="handleOdometerEnd"
          >
            <template
              #message="props"
            >
              <div id="miles-end-errors">
                {{ props.message }}
              </div>
            </template>
          </v-text-field>
          <v-text-field
            id="ifta-fuel-gallons-input"
            v-model="info.record.fuel_gallons"
            background-color="yellow"
            filled
            dense
            type="number"
            inputmode="numeric"
            hide-spin-buttons
            hide-details="auto"
            class="mt-1"
            label="Fuel Gallons"
            :disabled="isSubmitted"
            :error-messages="fuelGallonsErrors"
          >
            <template
              #message="props"
            >
              <div id="fuel-gallons-errors">
                {{ props.message }}
              </div>
            </template>
          </v-text-field>

          <v-text-field
            id="ifta-hour-meter-input"
            v-model="info.record.hour_meter"
            filled
            class="mt-1"
            dense
            hide-details="auto"
            :disabled="isSubmitted"
            type="number"
            inputmode="numeric"
            hide-spin-buttons
            label="Hour Meter"
            :error-messages="hour_meterErrors"
          >
            <template
              #message="props"
            >
              <div id="hour-meter-errors">
                {{ props.message }}
              </div>
            </template>
          </v-text-field>
        </v-col>

        <v-col class="pa-0 pl-1">
          <p
            style="font-size: 13px;"
            class="mb-3 text-center"
          >
            Total Miles: <span style="font-weight: 700;">{{ info.record.miles_total }}</span>
          </p>
          <v-text-field
            id="ifta-wa-miles-input"
            v-model="info.record.miles_wa"
            dense
            filled
            type="tel"
            pattern="[0-9]*"
            readonly
            :disabled="isSubmitted"
            label="WA Miles"
            :error-messages="miles_waErrors"
          >
            <template
              #message="props"
            >
              <div id="wa-miles-errors">
                {{ props.message }}
              </div>
            </template>
          </v-text-field>
          <v-text-field
            id="ifta-or-miles-input"
            :value="info.record.miles_or"
            dense
            filled
            :disabled="isSubmitted"
            type="tel"
            pattern="[0-9]*"
            label="OR Miles"
            :error-messages="miles_orErrors"
            @input="handleOregon($event)"
          >
            <template
              #message="props"
            >
              <div id="or-miles-errors">
                {{ props.message }}
              </div>
            </template>
          </v-text-field>
          <v-text-field
            id="ifta-offroad-miles-input"
            :value="info.record.miles_offroad"
            dense
            filled
            :disabled="isSubmitted"
            type="tel"
            pattern="[0-9]*"
            label="Off Road Miles"
            :error-messages="miles_offroadErrors"
            @input="handleOffroad($event)"
          >
            <template
              #message="props"
            >
              <div id="offroad-miles-errors">
                {{ props.message }}
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-col>

      <!-- Alert system for why save is disabled -->
      <v-col
        v-if="!viewAll || showInitInputs"
        id="ifta-alert-system"
        cols="12"
        class="py-0"
      >
        <div
          id="alert"
          class="text-center"
          dense
          style="color: red"
        >
          <p
            v-if="missingDescription"
            class="mb-0"
          >
            Please enter a description
          </p>
          <p
            v-else-if="missingCostcode"
            class="mb-0"
          >
            At least one entry is missing a cost code
          </p>
          <p
            v-else-if="!info.record.eq_num"
            class="mb-0"
          >
            Please enter a truck
          </p>
          <p
            v-else-if="info.entries < 1"
            class="mb-0"
          >
            Please enter a record
          </p>
          <p
            v-else-if="!date"
            class="mb-0"
          >
            Please enter a date
          </p>
          <p
            v-else-if="hasPreInspection && !hasPostInspection"
            class="mb-0"
          >
            Don't forget your post-trip!
          </p>
        </div>
      </v-col>
      <!-- Save & Delete Buttons  -->
      <v-col
        v-if="showInitInputs"
        xs="12"
        md="8"
        lg="6"
        class="align-content-center d-flex justify-center mt-1"
      >
        <v-btn
          id="ifta-delete-record-btn"
          class="flex-grow-1 mx-1"
          :disabled="!info.record.id || isSubmitted"
          color="error"
          :loading="deleting"
          @click="confirmDelete"
        >
          Delete
        </v-btn>
        <v-btn
          id="ifta-save-record-btn"
          class="flex-grow-1 mx-1"
          color="primary"
          :loading="saving"
          :disabled="handleSaveDisabled()"
          @click="saveRecord(info.entries)"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>Save
        </v-btn>
      </v-col>

      <!-- Empty column so items don't overlap with the v-footer, do not remove -->
      <v-col
        v-if="viewAll"
        cols="12"
        class="py-9"
      ></v-col>

      <!-- Ifta Day Footer -->
      <v-footer
        fixed
        class="pa-0"
        color="transparent"
      >
        <v-btn
          v-if="!hasPreInspection"
          id="ifta-pre-trip-btn"
          tile
          class="flex-grow-1 mx-1 pa-1 rounded-t"
          color="#49baf1"
          :disabled="!info.record.eq_num || hasPreInspection || isSubmitted"
          @click="handlePreInspection"
        >
          <v-icon
            left
            small
          >
            mdi-clipboard-check-outline
          </v-icon>
          pre-trip
        </v-btn>
        <v-btn
          id="ifta-add-drive-btn"
          color="green"
          tile
          class="flex-grow-1 mx-1 pa-1 rounded-t"
          :disabled="!info.record.eq_num || isSubmitted || disableFunctions"
          @click.stop="handleDrive();"
        >
          <v-icon
            left
          >
            mdi-truck-fast
          </v-icon>
          drive
        </v-btn>
        <v-btn
          id="ifta-add-duty-btn"
          color="orange"
          tile
          class="flex-grow-1 mx-1 pa-1 rounded-t"
          :disabled="!info.record.eq_num || isSubmitted || disableFunctions"
          @click.stop="handleDuty()"
        >
          <v-icon
            left
          >
            mdi-account-hard-hat
          </v-icon>
          Duty
        </v-btn>
        <v-btn
          v-if="hasPreInspection && !hasPostInspection"
          id="ifta-post-trip-btn"
          tile
          class="flex-grow-1 mx-1 pa-1 rounded-t"
          color="#49baf1"
          :disabled="!info.record.eq_num || hasPostInspection || !hasPreInspection || isSubmitted"
          @click="handlePostInspection"
        >
          <v-icon
            left
            small
          >
            mdi-clipboard-check-outline
          </v-icon>
          post-trip
        </v-btn>
      </v-footer>
      <!-- snack bar alerts -->
      <v-snackbar
        :value="isSubmitted"
        color="default"
        :timeout="-1"
      >
        This Record is submitted and is "View Only"
      </v-snackbar>
      <Alertbox
        v-bind="alert"
        @save="deleteRecord(), alert.display=false"
        @close="alert.display=$event"
      />
      <v-dialog
        v-model="deleteEntry"
        max-width="280px"
        persistent
      >
        <v-card
          id="ifta-delete-entry-modal"
        >
          <v-col align="center">
            <v-toolbar-title>
              Delete This Entry?
            </v-toolbar-title>
            <v-btn
              id="ifta-cancel-delete-entry-btn"
              outlined
              class="mr-2"
              color="red"
              @click="deleteEntry = false"
            >
              No
            </v-btn>
            <v-btn
              id="ifta-confirm-delete-entry-btn"
              outlined
              class="ml-2"
              color="success"
              @click="deleteSingleRecord(), deleteEntry = false
              "
            >
              Yes
            </v-btn>
          </v-col>
        </v-card>
      </v-dialog>
    </v-row>
    <v-skeleton-loader
      v-else
      type="list-item-avatar,
      divider, card-heading, list-item-three-line,
      divider, card-heading, list-item-three-line,
      divider, card-heading, list-item-three-line,
      divider, card-heading, list-item-three-line, divider"
    ></v-skeleton-loader>
  </div>
</template>
<script>
/* eslint-disable vue/no-undef-properties */
import moment from 'moment/src/moment';

import timeArrays from '../../../mixins/time-values.js';
import DriveEntry from './entry_drive';
import DutyEntry from './entry_duty';
import Inspection from './entry_inspection';
import AllEntryView from './allIftaEntries';
import Alertbox from '../../alert/alert.vue';

export default {
  name: 'IftaDay',
  components: {
    Inspection, DutyEntry, DriveEntry, Alertbox, AllEntryView,
  },
  mixins: [timeArrays],
  data() {
    return {
      inspection: {
        'Pre-trip inspection': false,
        'Post-trip inspection': false,
      },
      saving: false,
      allSavedRecordsForTodaysDate: [],
      deleting: false,
      loading: false,
      deleteEntry: false,
      removedEntries: [],
      validEntries: [],
      currentTimecardEntry: {
        id: null,
        job_num: null,
        cstcde: null,
        description: null,
        eqphrs: null,
        eq_num: null,
        start: null,
        stop: null,
        lunch: null,
        total: null,
        driving_record: null,
        offsite: null,
      },
      date: null,
      viewAll: true,
      disableFunctions: false,
      hasPreInspection: false,
      hasPostInspection: false,
      missingDescription: false,
      missingCostcode: false,
      isSubmitted: false,
      renderKey: 0,
      entryIndex: 0,
      totalDriveTime: 0,
      totalTime: 0,
      info: {
        record: {
          id: null,
          date: null,
          eq_num: null,
          fuel_gallons: null,
          ifta_id: null,
          miles_offroad: 0,
          miles_or: 0,
          miles_total: 0,
          miles_wa: 0,
          odometer_begin: null,
          odometer_end: null,
          trailer_1_eq_num: null,
          trailer_2_eq_num: null,
          hour_meter: 0,
        },
        entries: [],
      },
      alert: {
        display: false,
        message: '',
        confirm: false,
      },
    };
  },
  computed: {
    showInitInputs() {
      return (!this.info
      || this.entryIndex === this.info.entries.length
      || this.info.entries.length === 0);
    },
    showEntries() {
      const entryCount = this.info.entries.length;
      return (this.info
      && entryCount > 0
      && this.entryIndex < entryCount
      && this.currentTimecardEntry
      && !this.viewAll
      );
    },

    allDates() {
      const { last, present } = this.$store.getters.bothWeekDates || { last: [], present: [] };
      const lastDates = last.map((dayObj) => {
        return { text: `${dayObj.dayName} ${dayObj.date}`, value: dayObj.date };
      });
      const presentDates = present.map((dayObj) => {
        return { text: `${dayObj.dayName} ${dayObj.date}`, value: dayObj.date };
      });
      return [...lastDates, ...presentDates];
    },

    // validation error messages
    truckErrors() {
      const errors = [];
      if (this.isEmpty('eq_num')) {
        errors.push('Required');
      }
      return errors;
    },

    fuelGallonsErrors() {
      const errors = [];
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(Number(this.info.record.fuel_gallons))) {
        errors.push('Must be a number');
      }
      if (this.overMax('fuel_gallons', 2000)) {
        errors.push('Can\'t exceed 2000 gallons');
      }
      return errors;
    },
    odometer_beginErrors() {
      const errors = [];
      if (this.isEmpty('odometer_begin')) {
        errors.push('Required');
      }
      if (this.underMin('odometer_begin', 1)) {
        errors.push('Must be greater than 0');
      }
      if (!this.noDecimal(this.info.record.odometer_begin)) {
        errors.push('Can\'t be a decimal');
      }
      return errors;
    },
    odometer_endErrors() {
      const errors = [];
      if (this.isEmpty('odometer_end')) {
        errors.push('Required');
      }
      if (this.underMin('odometer_end', Number(this.info.record.odometer_begin) + 1)) {
        errors.push('Must be greater than beginning odometer');
      }
      if (!this.noDecimal(this.info.record.odometer_end)) {
        errors.push('Can\'t be a decimal');
      }
      return errors;
    },
    miles_waErrors() {
      const errors = [];
      if (this.underMin('miles_wa', 0)) {
        errors.push('Can\'t be negative');
      }
      if (this.overMax('miles_wa', 10000)) {
        errors.push('Can\'t exceed 10,000 miles');
      }
      if (!this.noDecimal(this.info.record.miles_wa)) {
        errors.push('Can\'t be a decimal');
      }
      return errors;
    },
    miles_orErrors() {
      const errors = [];
      if (this.overMax('miles_or', Number(this.info.record.miles_total))) {
        errors.push('Can\'t exceed total miles');
      }
      if (this.underMin('miles_or', 0)) {
        errors.push('Can\'t be negative');
      }
      if (!this.noDecimal(this.info.record.miles_or)) {
        errors.push('Can\'t be a decimal');
      }
      return errors;
    },
    miles_offroadErrors() {
      const errors = [];
      if (this.overMax('miles_offroad', Number(this.info.record.miles_total))) {
        errors.push('Can\'t exceed total miles');
      }
      if (this.underMin('miles_offroad', 0)) {
        errors.push('Can\'t be negative');
      }
      if (!this.noDecimal(this.info.record.miles_offroad)) {
        errors.push('Can\'t be a decimal');
      }
      return errors;
    },
    hour_meterErrors() {
      const errors = [];
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(Number(this.info.record.hour_meter))) {
        errors.push('Must be a number');
      }
      if (this.underMin('hour_meter', 0)) {
        errors.push('Can\'t be negative');
      }
      return errors;
    },

    validationFailed() {
      const arr = [...this.hour_meterErrors,
        ...this.miles_offroadErrors,
        ...this.miles_orErrors,
        ...this.miles_waErrors,
        // ...this.odometer_endErrors,
        ...this.odometer_beginErrors,
        ...this.fuelGallonsErrors,
        ...this.truckErrors,
      ];
      return arr.length > 0;
    },
    lastUsedInfo() {
      return this.$store.getters.lastUsedInfo;
    },
    // DATA DROPDOWNS
    jobs() {
      if (this.$store.getters.jobs) {
        const jobArray = this.$store.getters.jobs.map((job) => {
          return { text: `${job.job_num} - ${job.job_name}`, value: job.job_num };
        });
        const lastJobs = this.lastUsedInfo.jobs
          .map((job) => {
            return { text: job.description, value: job.job_num };
          });
        return [
          { text: 'None', value: null },
          { header: 'Last Used Job #' },
          ...lastJobs,
          { divider: true },
          { header: 'All Job #' },
          ...jobArray,
        ];
      }
      return ['No Jobs'];
    },
    equipment() {
      const textArray = this.$store.getters.equipment.map((eq) => {
        return { text: `${eq.eq_num} ${eq.description}`, value: eq.eq_num };
      });
      const lastEquipment = this.lastUsedInfo.equipment
        .map((eq) => {
          return { text: eq.description, value: eq.eq_num };
        });
      return [
        { text: 'None', value: null },
        { header: 'Last Used Equipment' },
        ...lastEquipment,
        { divider: true },
        { header: 'All Equipment' },
        ...textArray,
      ];
    },
    trailers() {
      const eq = this.$store.getters.iftaTrailers.length > 0
        ? this.$store.getters.iftaTrailers
        : this.$store.getters.equipment;
      const trailers = eq.map((e) => {
        return { text: `${e.eq_num} - ${e.description}`, value: e.eq_num };
      });
      return [{ text: 'No Trailer', value: null }, ...trailers];
    },
    iftaEquipmentList() {
      if (this.$store.getters.iftaEquipment.length > 0) {
        const iftaEq = this.$store.getters.iftaEquipment.map((e) => {
          return { text: `${e.eq_num} - ${e.description}`, value: e.eq_num };
        });
        const lastEquipment = this.lastUsedInfo.equipment
          .map((eq) => {
            return { text: eq.description, value: eq.eq_num };
          });
        return [
          { text: 'No Equipment', value: null },
          { header: 'Last Used Equipment' },
          ...lastEquipment,
          { divider: true },
          { header: 'All IFTA Equipment' },
          ...iftaEq];
      }
      return this.equipment;
    },
  },
  watch: {
    info: {
      handler(val) {
        if (this.loading === true) {
          return;
        }
        this.generateValidationChecks(); // Generate validation and drive/total times
        if (!this.isSubmitted) {
          this.locallySaveRecord(val);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.loading = true;
    this.date = this.$store.getters.newDate;
    await this.setRecord(this.$store.getters.viewingIftaId);
    if (this.$route.params.id && this.$route.params.id.match(/new/)) {
      await this.handleNewIftaRecord();
    }
    const obj = (this.$store.getters.entries[this.date] || {});
    const allTime = obj.entries ? obj.entries.map((e) => {
      return { ...e };
    }) : [];
    this.allSavedRecordsForTodaysDate = allTime;
    this.loading = false;
  },
  methods: {

    // Takes in an increment, can be any positive or negative number
    // Increments main index by the given index, if possible, then sets the entry to view
    // NEVER change the currentTimecardEntry through any other method
    incrementEntry(inc) {
      const possible = this.entryIndex + inc;
      // If trying to go out of bounds, kill function
      if (possible === -1
      || possible > this.info.entries.length) {
        return;
      }
      this.entryIndex = possible;
      this.$nextTick(() => {
        const entry = this.info.entries[possible];
        if (entry) {
          this.currentTimecardEntry = entry;
          this.renderKey += 1;
        }
        this.viewAll = false;
      });
    },

    // Moves to view all
    switchView() {
      this.entryIndex = this.info.entries.length - 1;
      this.viewAll = true;
    },

    async handleNewIftaRecord() {
      const truck = await this.$store.dispatch('getMyVehicle');
      const arrOfAvailableTrucks = this.iftaEquipmentList.map((e) => {
        return e.value;
      });
      if (truck && arrOfAvailableTrucks.includes(truck)) {
        this.info.record.eq_num = truck;
        this.getMiles();
      }
    },

    openCostcodeDialog() {
      this.userAlert(
        `
Contact your super if unsure which cost code to use for your job.
Equipment repair/fueling cost codes can't be used with a job number unless it's a public job.
Equipment repair cost codes require an equipment number.
All other job cost codes are assigned by the PM.`,
      );
    },

    handleSaveDisabled() {
      return this.isSubmitted
      || this.info.entries < 1
      || this.missingDescription
      || this.missingCostcode
      || this.validationFailed
      || !this.date
      || (this.inspection['Pre-trip inspection'] && this.hasPreInspection)
      || (this.inspection['Post-trip inspection'] && this.hasPostInspection);
    },
    // Loops over the timecard entries once, to find if there is inspections, descriptions, or any submitted records
    // Also generates drive/total times for the user
    generateValidationChecks() {
      let pre = false;
      let post = false;
      let description = false;
      let cstcde = false;
      let submitted = false;
      let driveTime = 0;
      let totalTime = 0;
      this.info.entries.forEach((e) => {
        if (e.description === Object.keys(this.inspection)[0]) {
          pre = true;
        }
        if (e.description === Object.keys(this.inspection)[1]) {
          post = true;
        }
        if (!e.description) {
          description = true;
        }
        if (!e.cstcde) {
          cstcde = true;
        }
        if (e.submitted) {
          submitted = true;
        }
        if (e.driving_record === '1') {
          driveTime += Number(e.total);
        }
        totalTime += Number(e.total);
      });
      this.hasPreInspection = pre;
      this.hasPostInspection = post;
      this.missingDescription = description;
      this.missingCostcode = cstcde;
      this.isSubmitted = submitted;
      this.totalDriveTime = driveTime;
      this.totalTime = totalTime;
    },
    //handler for child entries values that change
    handleChangedEntry(entry, index) {
      this.info.entries.splice(index, 1, { ...entry, index }); // Update entry in a way that will trigger vue
      const otherEntries = this.allSavedRecordsForTodaysDate;
      const compareEntry = this.info.entries[index];

      // Clear validation errors
      this.validEntries = [];

      //If you edit a start/stop of a saved record, it removes it from the list of timecard entries for comparing
      //this way, the other records arent comparing their times to two versions of the record
      if (compareEntry.id) {
        for (let i = 0; i < otherEntries.length; i++) {
          if (
            otherEntries[i].id === compareEntry.id
          && (otherEntries[i].start !== compareEntry.start
            || otherEntries[i].stop !== compareEntry.stop)
          ) {
            this.allSavedRecordsForTodaysDate.splice(i, 1);
          }
        }
      }
    },
    // Set Record when computed property returns record
    async setRecord(id) {
      const unsaved = this.$store.getters.viewingUnsaved;
      const newr = id.match(/new/);
      if (newr && !unsaved) { // New record, just set id and date.
        this.info.record.id = id;
        this.info.record.date = this.$store.getters.newDate;
      } else if (unsaved || !this.$store.getters.online) { // Clicked unsaved, or clicked saved and is offline
        this.$store.dispatch('getUnsavedRecord', id);
        const selected = this.$store.getters.selectedRecord;
        if (!selected) { // No unsaved record found, kick em back.
          this.$store.commit('setSnackbar', {
            show: true,
            time: 2000,
            message: 'Unable to find offline record',
            color: '#553882',
          });
          this.handleBack();
        }
        this.info = selected;
      } else {
        await this.$store.dispatch('getIftaRecord', { ifta_id: id });
        let selected = this.$store.getters.selectedRecord;
        if (!selected) { // Call failed
          this.$store.dispatch('getUnsavedRecord', id);
          selected = this.$store.getters.selectedRecord;
          if (!selected) { // No fallback offline found. kick user back to main page.
            this.$store.commit('setSnackbar', {
              show: true,
              time: 2000,
              message: 'Failed to get record.',
              color: '#553882',
            });
            this.handleBack();
          } else { // Found a fallback, load that instead.
            this.$store.commit('setSnackbar', {
              show: true,
              time: 2000,
              message: 'Failed to get record, falling back to offline version',
              color: '#553882',
            });
          }
        } else {
          this.info = selected;
        }
      }
      this.generateValidationChecks(); // Manually force validation, setting info like we do is not reactive.
    },

    // Save Record any time anything changes to unsaved Store.
    locallySaveRecord(record) {
      this.$store.commit('setUnsavedRecord', record);
    },
    // Back Button
    handleBack() {
      this.$store.commit('set_selected_record', null);
      if (this.$route.path !== '/ifta') {
        this.$router.push('/ifta').catch(() => {});
      }
    },

    // Close the keyboard
    blurInputs() {
      document.activeElement.blur();
    },
    //Increment Time by 15 min from previous entry
    incrementTime(stop) {
      const inc = moment(stop, 'HH:mm:ss').add(15, 'minutes').format('HH:mm:ss');
      return inc === '00:00:00' ? '24:00:00' : inc; // Check so we dont increment to the morning
    },
    //Enables the handleDrive and handleDuty functions, this way they cant run more then once a second
    enableNewTimeFunctions() {
      setTimeout(() => {
        this.disableFunctions = false;
      }, 2000);
    },

    // DRIVE TIME BUTTON
    handleDrive() {
      if (this.disableFunctions) {
        return;
      }
      this.disableFunctions = true;
      let start = '05:00:00';
      let stop = '05:15:00';
      if (this.info.entries.length >= 1) {
        const lastEntry = this.info.entries[this.info.entries.length - 1];
        start = lastEntry.stop;
        stop = this.incrementTime(start);
      }
      const newEntry = {
        id: null,
        job_num: null,
        cstcde: '1003.000',
        start,
        stop,
        total: 0.25,
        eq_num: null,
        eqphrs: null,
        description: null,
        lunch: null,
        driving_record: '1',
      };
      const len = this.info.entries.push(newEntry);
      this.handleEntrySwitch(len);
      this.enableNewTimeFunctions();
    },
    // DUTY TIME BUTTON
    handleDuty() {
      if (this.disableFunctions) {
        return;
      }
      this.disableFunctions = true;
      let start = '05:00:00';
      let stop = '05:15:00';
      let jobNum = null;
      if (this.info.entries.length >= 1) {
        const lastEntry = this.info.entries[this.info.entries.length - 1];
        start = lastEntry.stop;
        stop = this.incrementTime(start);
        jobNum = lastEntry.job_num;
      }
      const newEntry = {
        id: null,
        job_num: jobNum,
        cstcde: null,
        start,
        stop,
        total: 0.25,
        eq_num: this.info.record.eq_num,
        eqphrs: null,
        description: null,
        lunch: null,
        offsite: null,
        driving_record: '0',
      };
      const len = this.info.entries.push(newEntry);
      this.handleEntrySwitch(len);
      this.enableNewTimeFunctions();
    },

    //PRE INSPECTION BUTTON
    // Creates a pre inspection entry, with time of 5:00 am if no records, or last records stop time
    handlePreInspection() {
      if (this.disableFunctions) {
        return;
      }
      this.disableFunctions = true;
      const existingEntries = this.info.entries.length > 0;
      const lastEntry = !existingEntries ? null : this.info.entries[this.info.entries.length - 1];
      const timeString = !existingEntries ? null : this.incrementTime(lastEntry.stop);
      const start = existingEntries ? lastEntry.stop : '05:00:00';
      const stop = existingEntries ? timeString : '05:15:00';
      const newEntry = {
        id: null,
        job_num: null,
        cstcde: '1015.000',
        start,
        stop,
        total: 0.25,
        eq_num: this.info.record.eq_num,
        eqphrs: null,
        description: Object.keys(this.inspection)[0],
        driving_record: '0',
      };
      const len = this.info.entries.push(newEntry);
      this.handleEntrySwitch(len);
      this.enableNewTimeFunctions();
    },

    // POST INSPECTION BUTTON
    handlePostInspection() {
      if (this.disableFunctions) {
        return;
      }
      const lastEntry = this.info.entries[this.info.entries.length - 1];
      const timeString = this.incrementTime(lastEntry.stop);
      const newEntry = {
        id: null,
        job_num: null,
        cstcde: '1015.000',
        start: lastEntry.stop,
        stop: timeString,
        total: 0.25,
        eq_num: this.info.record.eq_num,
        eqphrs: null,
        description: Object.keys(this.inspection)[1],
        driving_record: '0',
      };
      const len = this.info.entries.push(newEntry);
      this.handleEntrySwitch(len);
      this.enableNewTimeFunctions();
    },

    // Takes in the timecard length, and moves to it
    handleEntrySwitch(len) {
      // Find the difference between index of new record and currently viewed record
      const offset = (len - 1) - this.entryIndex;
      // Move over by offset, should always be at least zero
      this.incrementEntry(offset);
    },

    otherUsedTime(entryIndex) {
      const combinedWeeklyAndDriverEntries = [...this.info.entries, ...this.allSavedRecordsForTodaysDate];
      // create an object keyed by entry id out of the combined weekly and ifta records.
      const entriesById = {};
      combinedWeeklyAndDriverEntries.forEach((entry) => {
        if (entry.id) {
          entriesById[entry.id] = entry;
        }
      });

      const localEntry = this.info.entries[entryIndex];
      const localEntries = this.info.entries.filter((entry, index) => {
        // This filter is so a timecard entry being created/edited isn't being calculated in used time.
        return index !== entryIndex;
      });
      const weekly = Object.keys(entriesById).filter((id) => {
        // When records are saved they also appear in the timecard entries from weekly timecard.
        // This method removes the entries that are local to this record, and are represented
        // already in the weekly entries object.
        const entry = entriesById[id];

        if (this.$route.params.id.match(/new/)) {
          // if this is a new ifta record, return it to be included in list of start / stop times to check for overlap.
          return entry;
        }
        return entry.id !== localEntry.id;
      }).map((e) => {
        return entriesById[e];
      });

      // Now that all entries from local Ifta Record and weekly are combined and de-duped,
      //we can create the array of start stops the child components use to check if time is used
      const startStops = [];
      [...localEntries, ...weekly].forEach((entry) => {
        // create array of start stops but allow entries that have been deleted locally.
        const removedEntriesWithoutNulls = this.removedEntries.filter((i) => {
          return i.id !== null;
        }).map((e) => {
          return e.id;
        });
        if (entry.start && entry.stop && !removedEntriesWithoutNulls.includes(entry.id)) {
          startStops.push([entry.start, entry.stop]);
        }
      });
      return startStops;
    },

    // Returns true if entry is overlapping with something
    overLappingRecord(entry, otherTimes) {
      const format = 'hh:mm:ss';
      const start = moment(entry.start, format);
      const stop = moment(entry.stop, format);
      return otherTimes.some((time) => {
        const usedStart = moment(time[0], format);
        const usedStop = moment(time[1], format);
        return (start.isSame(usedStart))
        || (start.isBetween(usedStart, usedStop) || stop.isBetween(usedStart, usedStop))
        || (start.isBefore(usedStart) && stop.isAfter(usedStart));
      });
    },
    // EQUIPMENT MILES OREGON INPUT
    handleOregon(event) {
      const miles = Number(event) || 0;
      const wa = this.info.record.miles_total - miles - this.info.record.miles_offroad;
      this.info.record.miles_or = miles;
      this.info.record.miles_wa = wa;
    },

    // EQUIPMENT MILES OFFROAD INPUT
    handleOffroad(event) {
      const miles = Number(event) || 0;
      const wa = this.info.record.miles_total - this.info.record.miles_or - miles;
      this.info.record.miles_offroad = miles;
      this.info.record.miles_wa = wa;
    },
    toTwelveHr(time) {
      if (!time) {
        return null;
      }
      return moment(time, 'HH:mm:ss').format('hh:mm A');
    },
    toProperTime(time) {
      if (!time) {
        return null;
      }
      const form = time.split(':');
      return form.length > 1 ? time : `${time}:00`;
    },
    // HOURS INPUT
    handleHours(entry) {
      if (entry.start && entry.stop) {
        const time = this.startStopDifference(entry.start, entry.stop);
        const lunch = Number(entry.lunch) || 0;
        // eslint-disable-next-line no-param-reassign
        entry.total = time - lunch;
      }
    },
    //Odometer Inputs handler
    handleOdometerBegin(odo) {
      const miles = Number(odo);
      this.info.record.odometer_begin = miles;
      if (odo && this.info.record.odometer_end) {
        const total = this.info.record.odometer_end - miles;
        this.info.record.miles_total = total;
        this.info.record.miles_wa = total;
      }
    },
    handleOdometerEnd(odo) {
      const miles = Number(odo);
      this.info.record.odometer_end = miles;
      if (this.info.record.odometer_begin && miles) {
        const total = miles - this.info.record.odometer_begin;
        this.info.record.miles_total = total;
        this.handleOregon(this.info.record.miles_or);
      }
    },

    //Date input handler
    handleDate() {
      this.$store.commit('deleteLocallySavedRecord', this.info);
      this.$store.commit('setNewDate', this.date);
      this.locallySaveRecord(this.info);
      //Because of the date change, the allsavedRecordsFortodaysDate has to be refound
      const allTime = (this.$store.getters.entries[this.date] || {}).entries.map((e) => {
        return { ...e };
      }) || [];
      this.allSavedRecordsForTodaysDate = allTime;
    },

    //START STOP DIFFERENCE
    startStopDifference(start, stop) {
      const startTime = moment.duration(start).asHours();
      const stopTime = moment.duration(stop).asHours();
      return stopTime - startTime;
    },

    // GET MILES FOR VEHICLE
    async getMiles() {
      const response = await this.$store.dispatch('getMiles', this.info.record.eq_num);
      const data = response.data || {};
      this.handleOdometerBegin(data.current_miles || 0);
    },

    // SEND TO TEMP RECORD DATABASE
    async saveRecord(entries) {
      this.saving = true;
      const record = this.serializeData(entries);
      const response = await this.$store.dispatch('saveDriverRecord', record);
      if (response) {
        if (response.status === 'success' && response.message === 'success') {
          await this.$store.commit('deleteLocallySavedRecord', this.info.record.id);
          await this.$store.dispatch('getEntries');
          this.$store.commit('setSnackbar', {
            show: true,
            time: 1000,
            message: 'Record Saved',
            color: 'success',
          });
          this.handleBack();
        } else if (response.status === 'error' && response.data) {
          this.handleFailedValidation(response.data, response.message);
          this.$store.commit('setSnackbar', {
            show: true,
            time: 1000,
            message: 'Record Failed Validation',
            color: 'warning',
          });
        } else if (!response.status) {
          this.$store.commit('setSnackbar', {
            show: true,
            time: 1000,
            message: 'Network connection slow, added to offline',
            color: '#553882',
          });
          this.handleBack();
        } else {
          this.$store.commit('setSnackbar', {
            show: true,
            time: 1000,
            message: 'Record Not Saved',
            color: 'warning',
          });
        }
      }
      this.saving = false;
    },
    serializeData(entries) {
      const serializedEntries = entries.map((entry) => {
        return {
          id: entry.id || null,
          job_num: entry.job_num || null,
          cstcde: entry.cstcde,
          description: entry.description,
          eqphrs: parseFloat(entry.eqphrs),
          eq_num: entry.eq_num || null,
          start: entry.start,
          stop: entry.stop,
          lunch: Number(entry.lunch),
          total: entry.total,
          driving_record: entry.driving_record,
          offsite: entry.offsite,
        };
      });

      return {
        id: this.info.record.id.match(/new/) ? null : this.info.record.id,
        date: this.date,
        emp_num: this.$store.getters.user.emp_num,
        timecard_entries: serializedEntries,
        equipment_entry: {
          hour_meter: this.info.record.hour_meter,
          miles_or: this.info.record.miles_or,
          miles_wa: this.info.record.miles_wa,
          miles_offroad: this.info.record.miles_offroad,
          miles_total: this.info.record.miles_total,
          eq_num: this.info.record.eq_num,
          trailer_1_eq_num: this.info.record.trailer_1_eq_num,
          odometer_begin: this.info.record.odometer_begin,
          odometer_end: this.info.record.odometer_end,
          fuel_gallons: parseFloat(this.info.record.fuel_gallons),
        },
      };
    },
    handleFailedValidation(errors, message) {
      this.validEntries = errors;
      this.switchView();
      this.userAlert(message, false);
    },

    async deleteSingleRecord() {
      const entry = this.currentTimecardEntry;
      this.removedEntries.push(entry);
      this.info.entries.splice(this.entryIndex, 1);
      this.validEntries.splice(this.entryIndex, 1);
      this.switchView(); // Move to all view
    },
    confirmDelete() {
      this.userAlert('Are you sure you wish to delete?', true);
    },
    // Delete Record and Entries associated with it.
    async deleteRecord() {
      this.deleting = true;
      if (this.info.record.id.match(/new/) || this.$store.getters.viewingUnsaved) {
        await this.$store.commit('deleteLocallySavedRecord', this.info.record.id);
        this.$store.commit('setSnackbar', {
          show: true,
          time: 1000,
          message: 'Deleted Record',
          color: 'success',
        });
        this.handleBack();
      } else {
        const response = await this.$store.dispatch('deleteIfta', Number(this.info.record.id));
        if (response.status === 'success' && response.message === 'success') {
          await this.$store.commit('deleteLocallySavedRecord', this.info.record.id);
          await this.$store.dispatch('getEntries');
          this.$store.commit('setSnackbar', {
            show: true,
            time: 1000,
            message: 'Deleted Record',
            color: 'success',
          });
          this.handleBack();
        } else {
          this.$store.commit('setSnackbar', {
            show: true,
            time: 1000,
            message: 'Error trying to delete record, try again.',
            color: 'error',
          });
        }
      }
      this.deleting = false;
    },
    userAlert(message, confirm) {
      this.alert = {
        ...this.alert, message, display: true, confirm,
      };
    },
    /////////////////////////
    // Validation helpers
    ////////////////////////
    overMax(key, max) {
      return parseFloat(this.info.record[key]) > max;
    },
    underMin(key, min) {
      return parseFloat(this.info.record[key]) < min;
    },
    isEmpty(key) {
      return !this.info.record[key];
    },
    noDecimal(value) {
      return (value % 1 === 0);
    },
  },
};
</script>
<style scoped>
  .driveColor {
    border-left: .5rem solid #4caf50 !important;
  }
  .inspection {
    border-left: .5rem solid #49baf1 !important;
  }
  .duty {
    border-left: .5rem solid #ff9800 !important;
  }
  .validationError {
    border: 2px solid #ff5252 !important;
  }
</style>
<style>
  .v-messages__wrapper {
    padding-bottom: 10px;
  }
</style>
